import { Avatar } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GoGrabber } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import { patchRequest } from '../../../components/axiosClient';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { UPDATE_PROJECT_LEAD, UPDATE_PROJECT_LEAD_INDEX, UPDATE_PROJECT_TASKS_INDEX, UPDATE_TASK } from '../../../components/utils';
import { IoIosCheckmark } from 'react-icons/io';

const ProjectLeadListView = ({ projectLeadList, getLeads, handleView, handleLeadComplete }) => {

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState();
    const navigate = useNavigate();
    const rowRef = useRef();

    useEffect(() => {
        getProjectLeads();
    }, [projectLeadList]);


    const getProjectLeads = async () => {
        // const fetchedTasks = await fetchTasks();
        if (projectLeadList && projectLeadList.length > 0) {
            const data = {
                "R1": { title: 'Open', items: projectLeadList.filter(lead => lead.status === 'Open') },
                "R2": { title: 'In Meeting', items: projectLeadList.filter(lead => lead.status === 'In Meeting') },
                "R3": { title: 'Confirm-Sale', items: projectLeadList.filter(lead => lead.status === 'Confirm-Sale') },
                "R4": { title: 'Closed', items: projectLeadList.filter(lead => lead.status === 'Closed') },
            };
            setRows(data);
        }
    };

    const updateProjectLeadStatus = async (payload) => {
        setLoading(true);
        try {
            const res = await patchRequest(
                `${UPDATE_PROJECT_LEAD}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                return true;
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const updateProjectLeadIndex = async (data) => {
        const payload = {
            tasks: data
        }
        setLoading(true);
        try {
            const res = await patchRequest(
                `${UPDATE_PROJECT_LEAD_INDEX}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }

    }

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    const onDragEnd = async (result) => {

        if (!result.destination) return;

        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = rows[source.droppableId];
            const destColumn = rows[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
            const updatedColumnsData = {
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            }

            const updatedTask = {
                task_id: removed.id.toString(),
                status: rows[destination.droppableId].title,
            }
            const res = await updateProjectLeadStatus(updatedTask);
            if (res) {
                const transformedData = Object.keys(updatedColumnsData).map((key) => {
                    const statusObj = updatedColumnsData[key];
                    return {
                        title: statusObj.title || statusObj.items[0]?.status, // Use title or extract from items
                        taskIds: statusObj.items.map((lead) => lead.id), // Create array of lead IDs
                    };
                });
                await updateProjectLeadIndex(transformedData);
                await getLeads();
            }
        } else {
            // Handle moving within the same column
            const column = rows[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
            const updatedColumnsData = {
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            }
            const transformedData = Object.keys(updatedColumnsData).map((key) => {
                const statusObj = updatedColumnsData[key];
                return {
                    title: statusObj.title || statusObj.items[0]?.status, // Use title or extract from items
                    taskIds: statusObj.items.map((lead) => lead.id), // Create array of lead IDs
                };
            });
            await updateProjectLeadIndex(transformedData);
            await getLeads();
        }

    };

    return (
        <div className="px-2 h-[calc(100vh_-_270px)] xl:h-[calc(100vh_-_270px)] overflow-y-auto">
            {(projectLeadList?.length === 0 || (rows === null || rows === undefined)) ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="flex flex-col min-h-[500px] gap-2">
                        {/* Common Header */}
                        <div className="w-full grid grid-cols-8 border-b-[#EFEFEF] font-medium p-2" ref={rowRef}>
                            {/* <div className='col-span-0.5'></div> */}
                            <div className="col-span-1 pl-5">Lead ID</div>
                            <div className="col-span-2">Project Title</div>
                            <div className="col-span-1">Created Date</div>
                            <div className="col-span-1">Due Date</div>
                            <div className="col-span-2">Assigned</div>
                            <div className="col-span-1">Priority</div>
                        </div>

                        {Object.entries(rows).map(([columnId, column]) => (
                            <Droppable key={columnId} droppableId={columnId}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <div
                                            className={`${column?.title === "Closed"
                                                ? "text-[#EE404C] bg-[#ff00001a] mt-3"
                                                : column?.title === "Open"
                                                    ? "text-[#83BF6E] bg-[#83bf6e24]"
                                                    : column?.title === "Confirm-Sale"
                                                        ? "text-[#6F767E] bg-[#6F767E24] mt-3"
                                                        : column?.title === "In Meeting"
                                                            ? "text-[#8E59FF] bg-[#8e59ff1a] mt-3"
                                                            : "text-[#6F767E] bg-[#6F767E24]"
                                                } px-[10px] py-[2px] rounded self-start ${column.items.length === 0 ? "mb-4" : ""
                                                }`}
                                        >
                                            {column.title}
                                        </div>

                                        <div className="w-full grid">
                                            {column.items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                    {(provided, snapshot) => {
                                                        const draggableStyle = {
                                                            ...provided.draggableProps.style,
                                                            width: rowRef.current ? `${rowRef.current.offsetWidth}px` : 'auto',
                                                        };

                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={`grid grid-cols-8 px-2 py-1 ${snapshot.isDragging ? "shadow-lg" : ""} ${item?.member_removed == "true" ? 'bg-[#f7f7f7]' : 'bg-white'} ${item?.is_completed ? 'opacity-50' : 'opacity-100'} hover:bg-[#f7f7f7] cursor-pointer border-b border-[#EFEFEF] `}
                                                                style={draggableStyle}
                                                                {...provided.draggableProps}
                                                                onClick={() => {
                                                                    if (window.getSelection().toString() === '') {
                                                                        handleView(item.id);
                                                                    }
                                                                }}
                                                            >
                                                                {/* Task */}
                                                                <div className="col-span-1 flex items-center gap-2 w-full pr-4">
                                                                    <div
                                                                        className="drag-handle cursor-grab mr-1"
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <GoGrabber />
                                                                    </div>
                                                                    <div className="flex items-center justify-start gap-1">
                                                                        <div className={`rounded-full border flex items-center ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                                                            onClick={async (e) => {
                                                                                e.stopPropagation();
                                                                                await handleLeadComplete(item.id, item?.is_completed);
                                                                                await getLeads();
                                                                            }}
                                                                        >
                                                                            <IoIosCheckmark />
                                                                        </div>
                                                                        <div>
                                                                            #{item.id}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-2 pr-4 flex items-center">
                                                                    <div className="flex items-center truncate">{item.title}</div>
                                                                </div>
                                                                <div className="col-span-1 pr-4 flex items-center">
                                                                    {item?.createdAt ? dayjs(item?.createdAt).format("DD-MM-YYYY") : "--"}
                                                                </div>
                                                                <div className="col-span-1 pr-4 flex items-center">
                                                                    {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                                                </div>

                                                                <div className="col-span-2 pr-4">
                                                                    {
                                                                        item?.assignee ?
                                                                            <div className="flex justify-start items-center gap-2">

                                                                                <Avatar
                                                                                    src={item.assignee.profile_image}
                                                                                    className="h-7 w-7"
                                                                                />
                                                                                <span>{item?.assignee?.first_name} {item?.assignee?.last_name}</span>
                                                                            </div> :
                                                                            '--'
                                                                    }
                                                                </div>
                                                                <div className="col-span-1 flex items-center">
                                                                    {item?.priority ? (
                                                                        <span className="flex flex-row gap-1 items-center">
                                                                            {customIcons[item?.priority]} {item?.priority}
                                                                        </span>
                                                                    ) : '--'}
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                        </div>


                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </DragDropContext>
            )}
        </div>
    );
};


export default ProjectLeadListView