import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from '@mui/material';
import Select from "react-select";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ListIcon } from '../../../components/assets/board_view_icon.svg';
import { ReactComponent as BoardIcon } from '../../../components/assets/List_view_icon.svg';
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest, patchRequest, postRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { GET_TRAINEE_TASK, TRAINEE_TASK_CREATE, TRAINEE_UPDATE_TASK } from '../../../components/utils';
import TrainingTaskKanabanView from './TrainingTaskKanabanView';
import TrainingTaskListView from './TrainingTaskListView';
import { Col, Row } from "react-bootstrap";
import Modal from '../Modal/Modal';
import validator from "validator";

const TrainingList = ({ trainingId }) => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [leadBoardView, setLeadBoardView] = useState(false);
  const [listTrainingTask, setListTrainingTask] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [taskData, setTaskData] = useState({
    title: "",
    assigned_to: id,
    due_date: null,
    priority: null,
    description: null,
    status: "To do",
  });

  const customFormatOptionLabel = ({ label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{icon}</span>
      <span>{label}</span>
    </div>
  );

  const customIcons = {
    "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
    "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
    "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
  };
  const priorityOptionsList = ["Low", "Medium", "High"];
  const priorityOption = priorityOptionsList.map((props) => ({
    label: props,
    value: props,
    icon: customIcons[props]
  }));
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const handleOpenModal = (id) => {
    let status;
    if (id === '11') {
      status = "To do"
    } else if (id === '12') {
      status = "In progress"
    } else if (id === '13') {
      status = "To be verified"
    } else if (id === '14') {
      status = "Completed"
    } else {
      status = "To do"
    }
    setTaskData({ ...taskData, status: status });
    setIsOpen(true);
  }


  const handleAddTask = () => {
    if (validator.isEmpty(taskData.title)) {
        errorNotification("Task title is required");
    } else {
        addTask(taskData);
        setIsOpen(false);
    }
}

const addTask = async (payload) => {
    try {
        const res = await postRequest(TRAINEE_TASK_CREATE, payload, navigate);
        if (res.type === 1) {
            if (res.response.data.success) {
              getTrainingTasks();
                setTaskData({
                    title: "",
                    assigned_to: id,
                    due_date: null,
                    priority: null,
                    description: null,
                    status: "To do",
                })
                successNotification(res.response.data.message);
            } else {
                errorNotification(res.response.data.message);
            }
        }
        if (res.type === 2) {
            errorNotification(res.errormessage || res.errors);
        }
    } catch (error) {
        errorNotification(error.message);
    }
}





  const handleTaskComplete = async (id, is_completed) => {
    if (id == null || is_completed == null || id == undefined || is_completed == undefined) {
        return;
    }
    const payload = {
        task_id: id,
        is_completed: !is_completed
    }
    try {
        const res = await patchRequest(
            `${TRAINEE_UPDATE_TASK}`,
            payload,
            navigate
        );
        if (res?.response && res?.response.data.success) {
            return;
        } else {
            errorNotification(res?.errormessage);
        }
    } catch (error) {
        console.error(error);
        errorNotification(error);
    }
  }

  const getTrainingTasks = async () => {
    try {
      // setLoading(true);
      let apiUrl = `${GET_TRAINEE_TASK}?user_id=${id}`;
      const res = await getRequest(apiUrl, null, navigate);
      if (res.data && res.data.data) {
        setListTrainingTask(res.data.data);
      } else {
        setListTrainingTask([]);
      }
    } catch (error) {
      errorNotification(error.message || "Failed to fetch task list");
    } finally {
      setLoading(false);
    }
  }



  const handleView = (taskId) => {
    navigate(`/training/training-details/${taskId}?user_id=${id}`);
  };

  useEffect(() => {
    setLoading(true);
    getTrainingTasks();
  }, [])


  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className='p-3 h-full flex flex-col bg-[#fff] rounded-[12px] w-full'>
      <div className="flex justify-between items-center px-3">
        <div className="flex justify-center items-center gap-3">
          <span className="cursor-pointer">
            <ArrowBackIcon onClick={goBack} loading="lazy" />
          </span>
          {/* {role_accesses && ( */}
          <div>
            <button
              className=" bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
            onClick={handleOpenModal}
            >
              + Add Task
            </button>
          </div>
          {/* )} */}
        </div>
        <div className="flex justify-end items-center gap-4">
          <Tooltip
            title={leadBoardView ? 'List view' : 'Boards view'}
          >
            <button
              onClick={() => setLeadBoardView(!leadBoardView)}
              className='border-1 border-[#cccccc] py-[7px] px-2 rounded-md'>
              {leadBoardView ? <BoardIcon /> : <ListIcon />}
            </button>
          </Tooltip>
        </div>
      </div>
      <div className='grow flex flex-row justify-between items-center p-3 w-full overflow-y-auto'>
        {listTrainingTask?.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (
          <div className="w-full h-full">
            {
              !leadBoardView ?
                <TrainingTaskKanabanView
                  projectLeadList={listTrainingTask}
                  getTasks={getTrainingTasks}
                  handleTaskComplete={handleTaskComplete}
                  handleView={handleView}
                />
                :
                <TrainingTaskListView
                  projectLeadList={listTrainingTask}
                  getTasks={getTrainingTasks}
                  handleTaskComplete={handleTaskComplete}
                  handleView={handleView}
                />
            }
          </div>
        )}
      </div>

      <Modal
        className="min-w-[60vw] task-modal"
        title="Add task"
        onClose={() => setIsOpen(false)}
        show={isOpen}
      >
        <div className='modal-body flex flex-col gap-4' >
          <Row className=''>
            <Col xs={12}>
              <h6 className="mb-2">Task</h6>
              <input
                placeholder="Add Task"
                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                type="text"
                name="title"
                value={taskData.title}
                autoComplete="off"
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    title: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <h6 className="mb-2">Priority</h6>
              <Select
                className="rounded-lg text-capitalize"
                options={priorityOption}
                formatOptionLabel={customFormatOptionLabel}
                value={taskData.priority ?
                  { label: taskData.priority, value: taskData.priority, icon: customIcons[taskData.priority] } : null}
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    priority: e.value,
                  });
                }}
              />
            </Col>
            <Col xs={6}>
              <h6 className="mb-2">Due Date</h6>
              <input
                placeholder="Due Date"
                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                type="date"
                name="due_date"
                value={taskData.due_date}
                autoComplete="off"
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    due_date: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h6 className="mb-2">Description</h6>
              <textarea
                placeholder="Add description"
                className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-40"
                type="text"
                name="task"
                value={taskData.description}
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    description: e.target.value,
                  });
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                onClick={handleAddTask}
              >Save</button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default TrainingList