import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Edit from "../../../../src/components/assets/Edit.svg";
import disableicon from "../../../../src/components/assets/disableicon.svg";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Modal from "../Modal/Modal";
import { getRequest, patchRequest, postRequest } from "../../../components/axiosClient";
import { GET_EMPLOYEE_BY_ID, UPDATE_EMPLOYEE_STATUS, UPDATE_EMPLOYEE_PASSWORD, CREATE_INITIATE_EXIT, GET_INITIATE_EXIT, UPDATE_INITIATE_EXIT, REVIEW_VERIFY_PASSWORD } from "../../../components/utils";
import {
    errorNotification,
    successNotification,
} from "../../../components/toast-notification/common-toast";
import dayjs from "dayjs";
import { boundaryContext } from "../../../App";
import EmployeeProjects from "./EmployeeProjects";
import EmployeeDailyUpdate from "./EmployeeTimeSheet";
import Select from "react-select";
import { EmployeeReview } from "./EmployeeReview";
import { ReactComponent as CloseEye } from "../../../../src/components/assets/eye-icon-close.svg";
import { ReactComponent as OpenEye } from "../../../../src/components/assets/eye-icon-open.svg";

const EmployeeListDetails = () => {
    const { setEmployeeName } = useContext(boundaryContext);
    const [loading, setLoading] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);
    const [document, setDocument] = useState([]);
    const [status, setStatus] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [initiateExitModal, setInitiateExitModal] = useState(false);
    const id = useParams();
    const [isOnNotice, setIsOnNotice] = useState(false);
    const [isTerminated, setIsTerminated] = useState(false);
    const [initiateExitData, setInitiateExitData] = useState({
        user_id: id?.id,
        exit_reason: '1',
        reason: null,
        final_date_selection: '1',
        exit_date: dayjs().format("YYYY-MM-DD"),
        final_date: dayjs().add(45, 'day').format('YYYY-MM-DD'),
        comment: ''
    });
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [ratingTabUnlocked, setRatingTabUnlocked] = useState(false);
    const [activeTab, setActiveTab] = useState('about');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    //navigate to previous page
    const goBack = () => {
        navigate(-1);
    };
    const changePassword = () => {
        setPasswordModal(true);
    };
    const disableAccount = () => {
        setDisableModal(true);
    };
    const disableAccountClose = () => {
        setDisableModal(false);
    };
    const handleView = () => {
        navigate(`/employee-list/employee-details/edit-employee/${id?.id}`);
    };

    const data = {
        user_id: id?.id,
        status: status,
    };

    // for get Employee Information API Call
    const GetEmployee = async () => {
        try {
            const res = await getRequest(
                `${GET_EMPLOYEE_BY_ID}?user_id=${id?.id}`,
                null,
                navigate
            );
            if (res.data) {
                setEmployeeName(res?.data?.data?.first_name + " " + res?.data?.data?.last_name);
                setDocument(res?.data?.data);
                if (res.data.data.status !== 'terminated' && res.data.data.is_on_termination === "true") {
                    setIsOnNotice(true);
                }
                if (res.data.data.status == 'terminated') {
                    setIsTerminated(true);
                }
                setStatus(res?.data?.data?.status);
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
        }
    };

    // for Employee status API Call
    const EmployeeStatus = async () => {
        try {
            if (data.status === "active") { data.status = "inactive"; }
            else { data.status = "active"; }
            const res = await patchRequest(
                `${UPDATE_EMPLOYEE_STATUS}`, data, navigate
            );
            disableAccountClose();
            GetEmployee();
            successNotification(res.response.data.message);
        } catch (error) {
            errorNotification(error.message);
        }
    };

    // for Change Password API Call
    const UpdatePassword = async () => {
        if (newPassword !== confirmPassword) {
            errorNotification("Passwords do not match");
            return;
        }

        const passwordData = {
            user_id: id?.id,
            password: newPassword,
        };

        try {
            const res = await patchRequest(
                `${UPDATE_EMPLOYEE_PASSWORD}`, passwordData, navigate
            );
            setPasswordModal(false);
            setNewPassword('');
            setConfirmPassword('');
            successNotification(res.response.data.message);
        } catch (error) {
            errorNotification(error.message);
        }
    };

    useEffect(() => {
        GetEmployee();
    }, []);

    const handleExit = () => {
        if (!initiateExitData.reason) {
            errorNotification("Please select Reason for exit");
            return;
        }

        if (!dayjs(initiateExitData.exit_date).isValid()) {
            errorNotification("Please select Resignation date");
            return;
        }

        if (!dayjs(initiateExitData.final_date).isValid()) {
            errorNotification("Please select last working day");
            return;
        }

        if (isOnNotice) {
            const data = {
                initiate_id: initiateExitData.initiate_id,
                reason_initiate_exit: initiateExitData.exit_reason,
                reason_termination: initiateExitData.reason,
                last_working_day_type: initiateExitData.final_date_selection,
                termination_notice_date: initiateExitData.exit_date,
                last_working_day: initiateExitData.final_date,
                comment: initiateExitData.comment,
            }
            updateInitiateExit(data);
        } else {
            const data = {
                user_id: initiateExitData.user_id,
                reason_initiate_exit: initiateExitData.exit_reason,
                reason_termination: initiateExitData.reason,
                last_working_day_type: initiateExitData.final_date_selection,
                termination_notice_date: initiateExitData.exit_date,
                last_working_day: initiateExitData.final_date,
                comment: initiateExitData.comment,
            }
            sendInitiateExit(data);
        }
    }

    const updateInitiateExit = async (data) => {
        setLoading(true);
        try {
            let API_URL = UPDATE_INITIATE_EXIT;
            const res = await patchRequest(API_URL, data, navigate);
            if (res.type === 1) {
                setInitiateExitModal(false);
                setInitiateExitData({
                    user_id: id?.id,
                    exit_reason: '1',
                    reason: null,
                    final_date_selection: '1',
                    exit_date: dayjs().format("YYYY-MM-DD"),
                    final_date: dayjs().add(45, 'day').format('YYYY-MM-DD'),
                    comment: ''
                })
                successNotification(res.response.data.message);
            } else {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (err) {
            errorNotification("Failed to initiate exit");
        } finally {
            setLoading(false);
        }
    }

    const getExitData = async () => {
        setLoading(true);
        try {
            let API_URL = `${GET_INITIATE_EXIT}?user_id=${id?.id}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res?.data) {
                const data = res.data.data;
                setInitiateExitData({
                    initiate_id: data.id,
                    exit_reason: data.reason_initiate_exit || '1',
                    reason: data.reason_termination,
                    final_date_selection: data.last_working_day_type || '1',
                    exit_date: dayjs(data.termination_notice_date).format('YYYY-MM-DD'),
                    final_date: dayjs(data.last_working_day).format('YYYY-MM-DD'),
                    comment: data.comment || ''
                });
                setInitiateExitModal(true);
            } else {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (err) {
            errorNotification("Failed to fetch exit data");
        } finally {
            setLoading(false);
        }
    }

    const sendInitiateExit = async (data) => {
        setLoading(true);
        try {
            let API_URL = CREATE_INITIATE_EXIT;
            const res = await postRequest(API_URL, data, navigate);
            if (res.type === 1) {
                setInitiateExitModal(false);
                setInitiateExitData({
                    user_id: id?.id,
                    exit_reason: '1',
                    reason: null,
                    final_date_selection: '1',
                    exit_date: dayjs().format("YYYY-MM-DD"),
                    final_date: dayjs().add(45, 'day').format('YYYY-MM-DD'),
                    comment: ''
                })
                successNotification(res.response.data.message);
            } else {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (err) {
            errorNotification("Failed to initiate exit");
        } finally {
            setLoading(false);
        }
    }

    const reasonOptionList_1 = ["Explore Other careers", "Other", "Personal Reasons", "Relocating", "Retirement"];
    const reasonOption_1 = reasonOptionList_1.map((prop) => {
        return {
            value: prop,
            label: prop,
        }
    })

    const reasonOptionList_2 = ["Absconding", "Death", "Medical Condition", "Misconduct", "Performance Issue"];
    const reasonOption_2 = reasonOptionList_2.map((prop) => {
        return {
            value: prop,
            label: prop,
        }
    })


    const handleTabSelect = (key) => {
        if (key === 'rating' && !ratingTabUnlocked) {
            setShowPasswordModal(true);
        } else {
            setRatingTabUnlocked(false);
            setActiveTab(key);
        }
    };

    const handlePasswordSubmit = async () => {
        if (password) {
            await checkPassword(password);
        } else {
            errorNotification("Enter password please");
        }
    };

    const checkPassword = async (password) => {
        try {
            const payload = {
                password: password
            };
            const res = await postRequest(REVIEW_VERIFY_PASSWORD, payload, navigate);
            if (res?.type === 1) {
                setRatingTabUnlocked(true);
                setActiveTab('rating');
                setShowPasswordModal(false);
                setPassword('');
            } else {
                errorNotification(res.errormessage);
            }
        } catch {
            errorNotification("Invalid password");
        }
    }


    const handleModalClose = () => {
        setPassword('');
        setShowPasswordModal(false);
    };

    return (
        <div className="admin__program__manage admin__program__details__section admin_audit h-full flex flex-col box-border">
            <div className="go__back__btn">
                <div className="mt-1 ">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer" />
                </div>

            </div>
            <div className="setting__section grow overflow-y-auto">
                <div className="h-full flex flex-col">
                    <Tabs
                        defaultActiveKey="about"
                        id="about-tabs"
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="nav-pills program__manage mb-3"
                    >
                        <Tab eventKey="about" title="About" className="nav-link">
                            <div className="">
                                <div className="flex flex-col lg:flex-row justify-between ">
                                    <div className="flex align-middle">
                                        <h5 className="font-semibold text-[22px] mt-[8px]">Employee Information</h5>
                                    </div>
                                    {
                                        isTerminated == false &&
                                        <button
                                            className="bg-[#f47e53] text-white w-fit interFontFamily py-2 px-4 rounded-md text-16 cursor-pointer  lg:mt-0"
                                            onClick={() => handleView()}
                                        >
                                            <div className="flex gap-2 items-center">
                                                <img src={Edit} alt="" className="text-white" />
                                                <p>Edit</p>
                                            </div>
                                        </button>
                                    }
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 my-6">
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal">Employee ID :</span>
                                        <span className="text-orange-500 font-normal ml-2">{document?.employee_id}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Email Address :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.email}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">First Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.first_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Last Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.last_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Contact Number :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.contact_number}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Date of Birth :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.birth_date ? dayjs(document.birth_date).format("DD-MM-YYYY") : '--'}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Emergency Contact Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.emergency_contact_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Emergency Number :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.emergency_contact_no}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Department :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.department?.department_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Branch :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.branch?.branch_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Employee Role :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.role?.name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Technology :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.technology?.name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Joining Date :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.joining_date ? dayjs(document?.joining_date).format("DD-MM-YYYY") : '--'}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal">Reporting person :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">
                                            {document?.reporting_person?.first_name && document?.reporting_person?.last_name
                                                ? `${document.reporting_person.first_name} ${document.reporting_person.last_name}`
                                                : '--'}
                                        </span>
                                    </div>

                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Address :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.address}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        {document?.leaving_date ? (
                                            <>
                                                <span className="font-normal">Leaving Date :</span>
                                                <span className="ml-2 text-[#6F767E] font-normal">{document.leaving_date ? dayjs(document?.leaving_date).format("DD-MM-YYYY") : '--'}</span>
                                            </>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row justify-end mt-28 space-y-4 lg:space-y-0 lg:space-x-4">
                                    {isTerminated == false && <button
                                        className={`w-fit py-2 px-4 rounded-md text-16 cursor-pointer ${status === 'active' ? 'bg-white text-black border' : 'bg-[#f47e53] text-white'}`}
                                        onClick={disableAccount}
                                    >
                                        {status === 'active' ? 'Disable Login' : 'Enable Login'}
                                    </button>
                                    }
                                    {isTerminated == false &&
                                        <button className="bg-[#f47e53] text-white w-fit  py-2 px-4 rounded-md text-16 cursor-pointer" onClick={changePassword}>Change Password</button>
                                    }
                                    <button className="bg-[#f47e53] text-white w-fit  py-2 px-4 rounded-md text-16 cursor-pointer"
                                        onClick={() => {
                                            setDisableModal(false);
                                            setPasswordModal(false);
                                            if (isOnNotice || isTerminated) {
                                                getExitData();
                                            } else {
                                                setInitiateExitModal(true);
                                            }
                                        }}
                                    >{isTerminated ? "Exit Details" : "Initiate Exit"}</button>
                                </div>
                            </div>

                            <Modal
                                className="client-modal "
                                title="Change Password"
                                onClose={() => {
                                    setPasswordModal(false);
                                }}
                                show={passwordModal}
                            >
                                <div className="modal-body add__client__form">
                                    <div>
                                        <h6 className="modal-heading">New Password</h6>
                                        <input
                                            placeholder="New Password "
                                            className="client__name text-capitalize "
                                            type="text"
                                            autoComplete="off"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <h6 className="modal-heading">Confirm Password</h6>
                                        <input
                                            placeholder="Confirm Password "
                                            className="client__name text-capitalize "
                                            type="text"
                                            autoComplete="off"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="add__close__section mt-3">
                                        <button
                                            type="button"
                                            className="client__save__btn w-[100%]"
                                            onClick={() => { UpdatePassword(); }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                className="client-modal"
                                title="Please Confirm"
                                onClose={() => {
                                    setDisableModal(false);
                                }}
                                show={disableModal}
                            >
                                <div className="modal-body add__client__form">
                                    <div className="text-center">
                                        <div className="flex justify-center mb-4">
                                            <div className="">
                                                <img src={disableicon} alt="" />
                                            </div>
                                        </div>
                                        <p className="text-[#33383F] mb-6 font-medium capitalize ">
                                            Are you sure you want to disable <span className="text-[#f47e53]">unikwork</span> login for <span className="font-bold text-[#000000]">{document?.first_name} {document?.last_name}</span>
                                        </p>
                                        <div className="flex justify-center space-x-4">
                                            <button
                                                className="bg-[#ECEDF0] text-[#6F767E] px-[13px] py-[10px] border-0 rounded-[6px] font-medium w-[100%]"
                                                onClick={() => {
                                                    disableAccountClose();
                                                }}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                className="client__save__btn w-[100%]"
                                                onClick={() => {
                                                    EmployeeStatus();
                                                }}
                                            >
                                                Confirm
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                className=""
                                title="Initiate Exit"
                                onClose={() => {
                                    setInitiateExitModal(false);
                                }}
                                show={initiateExitModal}
                            >
                                <div className="modal-body">
                                    <Row className="mb-4">
                                        <Col xs={12}>
                                            <fieldset className="">
                                                <h6 className="mb-2">Reason for Initiating the exit?</h6>
                                                <div className="mb-2">
                                                    <input
                                                        type="radio"
                                                        id="option1"
                                                        name="exit_reason"
                                                        value="1"
                                                        className="rounded-lg border !border-[#cccccc] cursor-pointer"
                                                        checked={initiateExitData.exit_reason === "1"}
                                                        onChange={(e) =>
                                                            setInitiateExitData({ ...initiateExitData, exit_reason: e.target.value, reason: null })
                                                        }
                                                        disabled={isTerminated}
                                                    />
                                                    <label htmlFor="option1" className="ml-2 text-[#33383F]">Employee wants to reassign</label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="option2"
                                                        name="exit_reason"
                                                        value="2"
                                                        className="rounded-lg border !border-[#cccccc] cursor-pointer"
                                                        checked={initiateExitData.exit_reason === "2"}
                                                        onChange={(e) =>
                                                            setInitiateExitData({ ...initiateExitData, exit_reason: e.target.value, reason: null })
                                                        }
                                                        disabled={isTerminated}
                                                    />
                                                    <label htmlFor="option2" className="ml-2 text-[#33383F]">Company decides to terminate</label>
                                                </div>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-2">{initiateExitData?.exit_reason == '1' ? "Reason for Resigning" : "Reason for Terminating"}</h6>
                                            <Select
                                                name="reason"
                                                placeholder="Select Reason please"
                                                className="h-10 w-full rounded-lg"
                                                options={initiateExitData?.exit_reason == '1' ? reasonOption_1 : reasonOption_2}
                                                value={reasonOption_1.find(option => option.value === initiateExitData.reason) || reasonOption_2.find(option => option.value === initiateExitData.reason) || null}
                                                onChange={(selectedOption) => setInitiateExitData({ ...initiateExitData, reason: selectedOption?.value })}
                                                isDisabled={isTerminated}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-2">{initiateExitData?.exit_reason == '1' ? "Resignation date" : "Termination date"}</h6>
                                            <input
                                                type="date"
                                                className="h-10 w-full rounded-lg border !border-[#cccccc] p-2"
                                                value={initiateExitData.exit_date}
                                                onChange={(e) => {
                                                    const newExitDate = dayjs(e.target.value);
                                                    setInitiateExitData({
                                                        ...initiateExitData,
                                                        exit_date: newExitDate.format("YYYY-MM-DD"),
                                                        final_date: newExitDate.add(45, 'day').format("YYYY-MM-DD")
                                                    })
                                                }}
                                                disabled={isTerminated}

                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col xs={12} md={6}>
                                            <fieldset className="">
                                                <h6 className="mb-2">Last working day?</h6>
                                                <div className="mb-2">
                                                    <input
                                                        type="radio"
                                                        id="noticePeriod"
                                                        name="final_date_selection"
                                                        value="1"
                                                        className="rounded-lg border !border-[#cccccc] cursor-pointer"
                                                        checked={initiateExitData.final_date_selection === "1"}
                                                        onChange={(e) =>
                                                            setInitiateExitData({
                                                                ...initiateExitData,
                                                                final_date_selection: e.target.value,
                                                                final_date: dayjs(initiateExitData.exit_date).add(45, 'day').format("YYYY-MM-DD")
                                                            })
                                                        }
                                                        disabled={isTerminated}
                                                    />
                                                    <label htmlFor="noticePeriod" className="ml-2 text-[#33383F]">Complete notice period <span className="text-[#6d7177] text-sm">
                                                        {initiateExitData.exit_date && dayjs(initiateExitData.exit_date).isValid()
                                                            ? dayjs(initiateExitData.exit_date).add(45, 'day').format('DD MMM, YYYY')
                                                            : ''}
                                                    </span></label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="otherDate"
                                                        name="final_date_selection"
                                                        value="2"
                                                        className="rounded-lg border !border-[#cccccc] cursor-pointer"
                                                        checked={initiateExitData.final_date_selection === "2"}
                                                        onChange={(e) =>
                                                            setInitiateExitData({ ...initiateExitData, final_date_selection: e.target.value })
                                                        }
                                                        disabled={isTerminated}
                                                    />
                                                    <label htmlFor="otherDate" className="ml-2 text-[#33383F]">Other Date</label>
                                                </div>
                                            </fieldset>
                                        </Col>
                                        {
                                            initiateExitData.final_date_selection === '2' &&
                                            <Col xs={12} md={6}>
                                                <h6 className="mb-2">Other Date</h6>
                                                <input
                                                    type="date"
                                                    className="h-10 w-full rounded-lg border p-2 !border-[#cccccc]"
                                                    value={initiateExitData.final_date}
                                                    disabled={isTerminated}
                                                    onChange={(e) => setInitiateExitData({ ...initiateExitData, final_date: e.target.value })}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mb-4">
                                        <Col xs={12}>
                                            <h6 className="mb-2">Comment</h6>
                                            <textarea
                                                className="h-20 w-full rounded-lg border p-2 min-h-24 !border-[#cccccc]"
                                                placeholder="Write your response here"
                                                name="comment"
                                                value={initiateExitData.comment}
                                                disabled={isTerminated}
                                                onChange={(e) => setInitiateExitData({ ...initiateExitData, comment: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        isTerminated == false &&
                                        <Row>
                                            <Col xs={12}>
                                                <div className="flex gap-3 justify-end">
                                                    <button className="flex flex-row items-center gap-1 border justify-center text-black rounded-md p-2 font-semibold hover:!border-black w-[110px]" onClick={() => {
                                                        setInitiateExitModal(false);
                                                        setInitiateExitData({
                                                            user_id: id?.id,
                                                            exit_reason: '1',
                                                            reason: null,
                                                            final_date_selection: '1',
                                                            exit_date: dayjs().format("YYYY-MM-DD"),
                                                            final_date: dayjs().add(45, 'day').format('YYYY-MM-DD'),
                                                            comment: ''
                                                        })
                                                    }}>
                                                        Cancel
                                                    </button>
                                                    <button className="border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53] w-[150px]"
                                                        onClick={() => { handleExit(); }}
                                                    >
                                                        {isOnNotice ? "Update" : "Initiate Exit"}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Modal>

                        </Tab>
                        <Tab
                            eventKey="project"
                            title="Project"
                            className="h-full"
                        >
                            {activeTab === 'project' && <EmployeeProjects />}
                        </Tab>
                        <Tab
                            eventKey="time-sheet"
                            title="Time Sheet"
                            className="h-full"
                        >
                            {activeTab === 'time-sheet' && <EmployeeDailyUpdate />}
                        </Tab>
                        <Tab eventKey="rating" title="Rating"
                            className="h-full">
                            {ratingTabUnlocked && <EmployeeReview />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Modal
                className="rating-tab-password-modal"
                key="project"
                title={"Verify Your self"}
                onClose={handleModalClose}
                show={showPasswordModal}
            >
                <div className='p-2'>
                    <div className='mb-4'>
                        <h6 className="mb-2 ml-1">PassWord</h6>
                        <input
                            placeholder="Enter your password"
                            className="h-10 w-full rounded-lg border p-[6px] relative"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            onClick={() => { setShowPassword(!showPassword) }}
                            className="absolute right-8 top-[calc(50%_-_18px)] transform -translate-y-1/2 h-[18px] w-[18px] cursor-pointer"
                        >
                            {!showPassword ? (
                                <CloseEye />
                            ) : (
                                <OpenEye />
                            )}
                        </span>
                    </div>
                    <div className="text-right">
                        <button className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                            onClick={handlePasswordSubmit}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            </Modal>
            <style>
                {
                    `
                        .rating-tab-password-modal .modal-content{
                            width:450px;
                        }

                        .tab-content{
                            flex-grow : 1;
                            overflow-y : auto;
                        }
                    `
                }
            </style>
        </div>
    );
};

export default EmployeeListDetails;
