import React, { useCallback, useEffect, useState } from 'react'
import high from '../../../components/assets/high_priority.svg'
import normal from '../../../components/assets/normal_priority.svg'
import low from '../../../components/assets/low_priority.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomPagination from '../../../components/common/CustomPagination'
import RequestIconActive from '../../../components/assets/request_icon_active.svg'
import clock_inActive from '../../../components/assets/clock_inActive.svg'
import start_button from '../../../components/assets/start-icon.svg'
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest } from '../../../components/axiosClient'
import { errorNotification } from '../../../components/toast-notification/common-toast'
import { GET_USER_COMPLETED_TASKS } from '../../../components/utils'
import LoadingSpinner from '../../../components/loader/LoadingSpinner'
import { FaStop } from "react-icons/fa6";
import dayjs from 'dayjs'
import { useRef } from 'react'
import { IoIosCheckmark } from 'react-icons/io'

const MyCompleteTaskList = ({ onStart, handlePause, runningTask, handleRequestModal, handleTaskComplete }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [firstLoading, setFirstLoading] = useState(false);
    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 40;
    const [page, setPage] = useState(1);
    const [completeTaskList, setCompleteTaskList] = useState([]);
    const taskListRef = useRef();
    const [hasMoreData, setHasMoreData] = useState(true);

    const handleTaskView = (id, projectId) => {
        if (projectId) {
            navigate(`/my-task/task-details/${id}?project_id=${projectId}`);
        } else {
            navigate(`/my-task/task-details/${id}`);
        }
    };

    useEffect(() => {
        getCompleteTaskList();
    }, [])

    const getCompleteTaskList = async () => {
        try {
            // setFirstLoading(true);
            let apiUrl = `${GET_USER_COMPLETED_TASKS}?page=${1}&limit=${pageSize}&`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setCompleteTaskList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
                setPage(2);
            } else {
                errorNotification(res.errormessage);
                setCompleteTaskList([]);
                setCompleteTaskList(false);
            }
            setFirstLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    }

    const fetchData = useCallback(async () => {
        if (loading || pageInformation?.current_page >= pageInformation?.last_page) return;

        setLoading(true);
        try {
            let apiUrl = `${GET_USER_COMPLETED_TASKS}?page=${page}&limit=${pageSize}&`;
            const res = await getRequest(apiUrl, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setCompleteTaskList((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }
            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false); // No more data available
            }

        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false); // Stop loading
        }
    }, [page, loading, hasMoreData]);

    const handleScroll = () => {
        if (taskListRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = taskListRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 200) {
                if (!loading) {
                    fetchData();
                }
            }
        }
    };

    useEffect(() => {
        const divElement = taskListRef.current;
        let debounceTimeout;

        const handleDebouncedScroll = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(handleScroll, 200);
        };

        if (divElement) {
            divElement.addEventListener("scroll", handleDebouncedScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleDebouncedScroll);
            }
            clearTimeout(debounceTimeout);
        };
    }, [fetchData, loading]);

    const customIcons = {
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />
    };

    return firstLoading ? (
        <LoadingSpinner />
    ) : (
        <div className='py-2 px-1 h-[calc(100vh_-320px)] overflow-y-auto' ref={taskListRef}>

            {completeTaskList?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='min-h-[500px] flex flex-col justify-between'>
                    <div className='overflow-x-auto'>
                        <table className="table max-w-full overflow-x-auto">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!w-[35%] !border-b-[#EFEFEF] font-medium">
                                        Name
                                    </th>
                                    <th scope="col" className=" !border-b-[#EFEFEF] font-medium">
                                        Project
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Due Date
                                    </th>
                                    {/* <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                    Status
                                </th> */}
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Priority
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Total Time
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium text-right">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    completeTaskList?.map((item, index) => {
                                        const timeDuration = dayjs.duration(item?.duration, 'seconds');
                                        const hours = String(timeDuration.hours()).padStart(2, '0');
                                        const minutes = String(timeDuration.minutes()).padStart(2, '0');
                                        const formattedTime = `${hours}:${minutes}`;
                                        return (
                                            <tr
                                                className={`align-middle border-b-0 hover:bg-[#f7f7f7] hover:rounded-lg overflow-hidden cursor-pointer ${item?.is_completed ? 'opacity-50' : 'opacity-100'}`}
                                                onClick={() => handleTaskView(item.id, item?.project?.id)} key={index}>
                                                <td className="border-b-0 !border-b-[#EFEFEF] p-2 !max-w-[35vw]">
                                                    <div className='flex items-center gap-1'>
                                                        <div className={`rounded-full border flex items-center 
                                                                            ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                                            onClick={async (e) => {

                                                                e.stopPropagation();
                                                                await handleTaskComplete(item.id, item?.is_completed);
                                                                await getCompleteTaskList();
                                                            }}
                                                        >
                                                            <IoIosCheckmark />
                                                        </div>
                                                        <div className="truncate">
                                                            {item?.title || "--"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.project?.name || '--'}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                                </td>
                                                {/* <td className="table_tr_style align-middle">
                                                <div className={`${item?.status === "In progress" ? "text-[#db763b] bg-[#eeb08c3d]" : item?.status === "Completed" ? "text-[#83BF6E] bg-[#83bf6e24]" : item?.status === "To be verified" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item?.status === "Pending" ? "text-[#6F767E] bg-[#6f767e2b]" : "text-[#6F767E] bg-[#6f767e2b]"}
                                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[130px] d-flex justify-center`}>
                                                    {item?.status}
                                                </div>
                                            </td> */}
                                                <td className="table_tr_style align-middle">
                                                    {
                                                        item?.priority ?
                                                            <span className={`flex flex-row gap-1 justify-start items-center`}>
                                                                {customIcons[item?.priority]} {item?.priority}
                                                            </span>
                                                            : '--'
                                                    }
                                                </td>
                                                <td className="table_tr_style align-middle">
                                                    <div className="flex flex-row justify-start items-center gap-1">
                                                        <img src={clock_inActive} /> <span className='w-[40px]'>{formattedTime}</span>
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] text-right">
                                                    <div className='flex flex-row items-center gap-2 justify-end'>
                                                        {
                                                            runningTask && runningTask?.task.id === item.id ?
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2 hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handlePause()
                                                                    }}
                                                                >
                                                                    <FaStop className='h-[12px]' /> Stop
                                                                </button>
                                                                :
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2  hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onStart(item, e)
                                                                    }}
                                                                >
                                                                    <img className='h-[11px] w-[11px]' src={start_button} alt="start_button" /> {item?.duration != 0 ? "Continue" : "Start"}
                                                                </button>
                                                        }
                                                        <img src={RequestIconActive} alt='!!' className='h-5 w-5 cursor-pointer'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRequestModal(item);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            loading &&
                            <div className='w-full my-2 flex items-center justify-center'>
                                Loading...
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default MyCompleteTaskList