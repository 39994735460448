import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IoIosCheckmark } from "react-icons/io";
import secureLocalStorage from 'react-secure-storage';
import { boundaryContext } from '../../../../App';
import high from '../../../../components/assets/high_priority.svg';
import low from '../../../../components/assets/low_priority.svg';
import normal from '../../../../components/assets/normal_priority.svg';

const TaskInformation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    min-height: 106px;
    border-radius: 16px;
    max-width: 311px;
    background: white;
    margin-top: 15px;
    gap: 0.75rem;
    box-sizing: border-box !important; 
    border-style : solid;
    border-width: 1px;
    border-color:white;

    &:hover {
        border-color : black;
    }
    
    .secondary-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 400px;
        color: #7d7d7d;
    }
`;

const TaskCard = ({ item, index, onDoubleClick, handleTaskComplete, getTaskList }) => {

    const role_access = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";
    const { userData } = useContext(boundaryContext);

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    return (
        <Draggable key={(item.id)} draggableId={item.id.toString()} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={onDoubleClick}
                    className='!cursor-pointer border-0 !border-black hover:border-1'
                >
                    <TaskInformation className={`${item?.member_removed == "true" ? '!bg-[#fbebeb] border border-[#f1c1c1]' : ''}  ${item?.is_completed ? 'opacity-60' : 'opacity-100'} `}>
                        <div className='flex items-center gap-1 w-full'>
                            <div className={`rounded-full border flex items-center ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await handleTaskComplete(item.id, item?.is_completed);
                                    await getTaskList();
                                }}
                            >
                                <IoIosCheckmark />
                            </div>
                            <p className='truncate'>{item?.title}</p>

                        </div>
                        <div className='flex justify-between items-center w-full text-sm text-[#7D7D7D]'>
                            <span>{item?.assignee?.first_name && item?.assignee?.last_name ? (item?.assignee?.first_name + " " + item?.assignee?.last_name) : "--"}</span>
                            <span>{item?.due_date && dayjs(item?.due_date).format('DD-MM-YYYY')}</span>
                        </div>
                        <div className='flex justify-between items-center text-sm w-full text-[#7D7D7D]'>
                            <div>
                                {
                                    item?.priority ?
                                        <span className={`flex flex-row gap-1 justify-start items-center`}>
                                            {customIcons[item?.priority]} {item?.priority}
                                        </span>
                                        : '--'
                                }
                            </div>
                        </div>
                    </TaskInformation>
                </div>
            )}
        </Draggable>
    );
};

export default TaskCard;
