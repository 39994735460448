import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import { boundaryContext } from "../../../App";
import NewModal from "../../../views/admin/Modal/NewModal";
import userAvtar from "../../assets/HeaderAvtar.svg";
import logo_main from "../../assets/unikwork-logo3x.png";
import logo_second from "../../assets/logo_second.png";
import { postRequest } from "../../axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../toast-notification/common-toast";
import { GET_PROFILE, LOGOUT } from "../../utils";
import "./Sidebar.css";
import SidebarListItem, { styles } from "./SidebarListItem";
import AddUpdate from "../../addUpdate/AddUpdate";
import { Avatar } from "@mui/material";

const Sidebar = ({ toggleSidebar, isSidebarOpen, setSidebarOpen }) => {
  const role = secureLocalStorage.getItem("role");
  const location = useLocation(); // React Hook
  const [locations, setLocations] = useState();
  const [loading, setLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLocations(location.pathname.slice(1));
  }, [location]);
  const { profileImage, setProfileImage, userData, setUserData, socket, setSocket, inboxCount } = useContext(boundaryContext);
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses"));
  const locationProgram = locations?.split("/");
  const programPath = locationProgram?.includes("program");
  const locationReport = locations?.split("/");
  const reportPath = locationReport?.includes("report");

  const removeAuditActiveTab = () => {
    secureLocalStorage.removeItem("activetab");
  };

  const data = SidebarListItem().sidebarItem[role];
  const headerData = SidebarListItem().headerListItem[role];

  const pathname = `${location.pathname.split("/")[1]}${location.pathname.split("/")[2] ? "/" + location.pathname.split("/")[2] + "/:id" : ""}`;
  const isPathnameActive = location.pathname.split("/")[1];
  const profileImageNew = secureLocalStorage.getItem("profile_image");
  const userName = secureLocalStorage.getItem("username");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const d = new Date();
  let year = d.getFullYear();

  const handlemyLogout = async () => {
    setLoading(true);
    try {

      if (socket && socket.connected) {
        socket.disconnect();
        setSocket(null);
      }

      const res = await postRequest(LOGOUT, navigate, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          // setShow(false)
          secureLocalStorage.clear();
          setLoading(false);
          navigate("/");
          successNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOptionClick = (selectedOption) => {
    setShowModal(true);
  };

  const customMenu = ({ innerProps, children }) => (
    <div
      {...innerProps}
      onMouseEnter={() => setMenuIsOpen(true)}
      style={{
        cursor: "pointer",
        position: "absolute",
        right: "10px",
        top: "0px",
      }}
    >
      <div
        style={{
          backgroundColor: "transparent",
          height: "10px",
          width: "20px",
        }}
      >
        {" "}
      </div>
      {children}
    </div>
  );

  const options = [
    {
      value: handleOptionClick,
      label: "Log Out",
    },
  ];

  const getMyProfile = async () => {
    const getrole = secureLocalStorage.getItem("role");

    const API_URL = GET_PROFILE;
    // getrole === "member" ? GET_MEMBER_PROFILE : getrole === "company" ? GET_COMPANY_PROFILE : getrole === "hunter" ? GET_HUNTER_PROFILE : GET_ADMIN_PROFILE;
    const Token = secureLocalStorage.getItem("token");
    setLoading(true);
    axios
      .get(API_URL, { headers: { Authorization: Token } })
      .then((response) => {
        const data = response?.data?.data;
        setUserData(data);
        setProfileImage(data?.profile_image);
        // setSlackWebHook(data?.slack_web_hook);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white   ">
        <div className="px-3 py-[16px] 2xl:py-[28px] lg:px-5 lg:pl-3 relative">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 "
                onClick={toggleSidebar}
              >
                <RxHamburgerMenu style={{ fontSize: "20px" }} />
              </button>
              <div>
                <img
                  src={logo_main}
                  className="h-[22px] lg:h-[32px] ms-2 lg:ms-4 me-3 hidden lg:block"
                  alt="FlowBite Logo"
                />
                <img
                  src={logo_second}
                  className="h-[60px] lg:h-[32px] ms-2 lg:ms-4 me-3 lg:hidden"
                  alt="FlowBite Logo"
                />
                <div className="bg-[#00000014] h-[1px] w-[205px] absolute bottom-0 left-[25px]"></div>
              </div>
              {headerData?.map(({ title, to }, i) => {
                return (
                  pathname === to && (
                    <span
                      key={i}
                      className="self-center text-[30px] font-semibold sm:text-2xl lg:pl-[70px]"
                    >
                      {title}
                    </span>
                  )
                );
              })}
            </div>
            <div className="flex items-center me-3">
              <div className="flex items-center ms-3">
                <div
                  onMouseEnter={() => setMenuIsOpen(true)}
                  onMouseLeave={() => setMenuIsOpen(false)}
                >
                  <button
                    type="button"
                    className="flex rounded-full "
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    {
                      <Avatar
                        className="w-[48px] h-[48px] rounded-full  object-cover border-[1px] border-[#d3d3d3]"
                        src={profileImage ? profileImage : ""}
                        alt="userphoto"
                      />
                    }
                  </button>
                  <Select
                    placeholder="For Comapny"
                    value={"For Comapny"}
                    onChange={(selectedOption) =>
                      handleOptionClick(selectedOption)
                    }
                    options={options}
                    styles={styles}
                    className="basic-single"
                    classNamePrefix="select"
                    name="severity_picker"
                    components={{ Menu: customMenu }}
                    isSearchable={false}
                    menuIsOpen={menuIsOpen}
                  />
                </div>
                {/* dropdown-- */}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen  pt-[100px] 2xl:pt-[140px] transition-transform ${isSidebarOpen ? "" : "-translate-x-full"
          } bg-white  lg:translate-x-0 `}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4  bg-white dark:bg-gray-800 relative">
          <div className="space-y-2 font-medium h-full overflow-y-auto">
            {data?.map(
              (
                {
                  to,
                  title,
                  icon,
                  activeIcon,
                  show,
                  ricon,
                  disabled = false,
                  toolTipContent,
                },
                index
              ) => {
                const count = inboxCount || 0;
                return (

                  <div key={index}>
                    {show && (
                      <div
                        onMouseEnter={() => disabled && setShowTooltip(title)}
                        onMouseLeave={() => disabled && setShowTooltip(false)}
                        onClick={() => {
                          !disabled && navigate(to);
                          setSidebarOpen(false);
                          removeAuditActiveTab();
                        }}
                        className={`${(isPathnameActive === to ||
                          isPathnameActive?.split("/").includes(to?.split("/")[1])) &&
                          "sidebar__descTwo"
                          } ${disabled
                            ? "bg-[#efefef] cursor-not-allowed"
                            : "cursor-pointer"
                          } flex  p-[10px] relative items-center`}
                      >
                        {ricon ? (
                          <span
                            className={
                              isPathnameActive === to
                                ? "text-[#f47e53] text-[25px]"
                                : "text-[#6f767e]  text-[25px]"
                            }
                          >
                            {ricon}
                          </span>
                        ) : (
                          <img
                            src={isPathnameActive === to ? activeIcon : icon}
                            alt="icon"
                          />
                        )}
                        <span
                          className={`${isPathnameActive === to
                            ? "sidebar__text__active"
                            : "sidebar__text"
                            } hover:text-[#292929] pl-[10px] `}
                        >
                          {title}
                        </span>
                        {title === "Inbox" && count > 0 && (
                          <span className="bg-[#f47e53] text-white text-xs ml-1 font-normal h-[18px] w-[18px] flex justify-center items-center rounded-full">
                            {count}
                          </span>
                        )}
                      </div>
                    )}
                    {showTooltip === title && disabled && (
                      <span className="absolute bg-[#e0e0e0] text-white text-xs rounded py-1 px-3  whitespace-no-wrap -mt-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        {toolTipContent}
                      </span>
                    )}
                  </div>
                );
              }
            )}
            {/* <button data-tooltip-target="tooltip-default" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Default tooltip</button> */}
            {/* role === "super_admin" && */}
          </div>
          {/* {role_accesses[3].access_type === "Write" && (
            <div className="nav-item icon_margin sticky bottom-0 bg-white pb-2">
              <button
                className="add__Programs deactive__projectlist d-flex align-items-center hover__remove "
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Add Program"
                data-tooltip-place="right"
                onClick={() => setIsOpen(true)}
              />
              <span className="add_updates"> Add Updates</span>
            </div>
          )} */}
          {/* <div
            className={`${role !== "super_admin" ? "absolute bottom-10 " : "relative"
              }`}
          >
            <div
              className={`text-center pb-2 text-[#6f767e] text-[14px]  ${role !== "super_admin" ? "ms-[60px]" : " ms-[0px]"
                }`}
            >
              {year}©UNIKWORK
            </div>
            <div className="bg-[#00000014] h-[1px] w-[190px] absolute mb-5 left-[20px]"></div>
            <div
              className={`social__icon__style flex justify-center pt-2 ${role !== "super_admin" ? "ms-[40px]" : " ms-[0px]"
                }`}
            >
              <span className="ms-3">
                <a
                  href="https://twitter.com/Securrtech"
                  target="_blank"
                  className="text-[#d3d3d3] hover:text-[#d3d3d3]"
                >
                  <FaTwitter />
                </a>
              </span>
              <span className="ms-3">
                <a
                  href="https://www.linkedin.com/company/securrtech/"
                  target="_blank"
                  className="text-[#d3d3d3]  hover:text-[#d3d3d3]"
                >
                  <AiFillLinkedin />
                </a>
              </span>
              <span className="ms-3">
                <a
                  href="https://t.me/securrtech"
                  target="_blank"
                  className="text-[#d3d3d3]  hover:text-[#d3d3d3]"
                >
                  <FaTelegramPlane />
                </a>
              </span>
            </div>
          </div> */}
        </div>
      </aside>
      <NewModal
        title="Are you Sure?"
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <div className="modal-body add__cmp__form">
          <h6>Do you really want to log out?</h6>
          <div className="add__close__section">
            <button
              type="button"
              className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
              onClick={() => setShowModal(false)}
            >
              No
            </button>
            <button
              type="button"
              className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
              onClick={handlemyLogout}
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm  mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}{" "}
              Yes{" "}
            </button>
          </div>
        </div>
      </NewModal>
      <AddUpdate
        open={isOpen}
        setOpen={setIsOpen}
        getMyProfile={getMyProfile}
        setReload={setReload}
        reload={reload}
      />
    </>
  );
};

export default Sidebar;
