import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from '../../Modal/Modal';
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from '../../../../components/loader/LoadingSpinner';
import { ACTION_ON_TIMER_REQUESTS, GET_TASK_TIMER_REQUESTS } from '../../../../components/utils';
import { errorNotification, successNotification } from '../../../../components/toast-notification/common-toast';
import dayjs from 'dayjs';
import { getRequest, patchRequest } from '../../../../components/axiosClient';
import CustomPagination from '../../../../components/common/CustomPagination';
import noDataFound from "../../../../components/assets/No data-rafiki 1.svg";

const TasksRequest = ({ projectId, setCount }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [requestList, setRequestList] = useState([]);
    const [pageInformation, setPageInformation] = useState({});
    const [page, setPage] = useState(1);
    const [modalRequest, setModalRequest] = useState({});
    const pageSize = 10;

    useEffect(() => {
        getTaskRequest();
    }, [page])

    const handleModal = (prop) => {
        setModalRequest(prop);
        setIsOpen(!isOpen);
    }

    const getTaskRequest = async () => {
        try {
            setLoading(true);
            const API_URL = `${GET_TASK_TIMER_REQUESTS}?project_id=${projectId}&page=${1}&limit=${pageSize}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setRequestList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
                setCount(res.data.data.page_data.length);
                setPage(2);
            } else {
                errorNotification("No data found");
            }
            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleModalSubmit = (prop) => {
        if (modalRequest.start_time === null) {
            errorNotification("Please select start time");
            return;
        } else if (modalRequest.end_time === null) {
            errorNotification("Please select end time");
            return;
        } else if (modalRequest.date === null) {
            errorNotification("Please select date");
            return;
        } else {
            const payload = {
                timer_request_id: modalRequest.id,
                start_time: dayjs(modalRequest.start_time, "HH:mm").set('second', 59).format("HH:mm:ss"),
                end_time: dayjs(modalRequest.end_time, "HH:mm").format("HH:mm:ss"),
                date: modalRequest.date,
                note: modalRequest.note,
                reason: modalRequest.reason,
                status: prop === true ? 'Accepted' : 'Rejected' //Rejected,Accepted
            }

            sendRequestResult(payload);
        }
    }

    const sendRequestResult = async (payload) => {
        setLoading(true);
        try {
            const API_URL = `${ACTION_ON_TIMER_REQUESTS}`;
            const res = await patchRequest(API_URL, payload, navigate);
            if (res.type === 1 && res.response.data) {
                successNotification(res.response.data.message);
                setIsOpen(false);
                getTaskRequest();
            } else {
                errorNotification(res.errormessage);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            errorNotification(error);
        }
    }


    const goBack = () => {
        navigate(-1);
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="px-[20px] py-2 bg-white rounded-lg">
            {/* <div className={`go__back__btn flex flex-row gap-3 items-center cursor-pointer mb-2`}>
                <ArrowBackIcon onClick={goBack} loading="lazy" />
            </div> */}
            <div>

                {requestList?.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (
                    <div className='min-h-[600px]'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='!border-b-[#d3d3d3] font-medium'>Task </th>
                                    <th className='!border-b-[#d3d3d3] font-medium'>Request By</th>
                                    <th className='!border-b-[#d3d3d3] font-medium'>Date</th>
                                    <th className='!border-b-[#d3d3d3] font-medium'>Request Type</th>
                                    <th className='!border-b-[#d3d3d3] font-medium'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    requestList.map((request, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="table_tr_style align-middle !max-w-[20vw]">
                                                    <div className='truncate'>
                                                        {request.task.title}
                                                    </div>
                                                </td>
                                                <td className="table_tr_style align-middle">
                                                    {request.requested_by.first_name} {request.requested_by.last_name}
                                                </td>
                                                <td className="table_tr_style align-middle">{dayjs(request.date).format("DD-MM-YYYY")}</td>
                                                <td className="table_tr_style align-middle">{request.request_type}</td>
                                                <td className='text-right'>
                                                    <button className='border-0 text-white rounded-md py-2 px-3 font-medium bg-[#f47e53]'
                                                        onClick={() => handleModal(request)}
                                                    >view</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            <CustomPagination
                                page={page}
                                pageInformation={pageInformation}
                                setPage={setPage}
                                totalPages={pageInformation?.last_page}
                            />
                        }
                    </div>
                )}
                <Modal
                    className=""
                    title={"Task Request"}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    show={isOpen}
                >
                    <div className="modal-body flex flex-col gap-3">
                        <Row className=''>
                            <Col xs={12}>
                                <h6 className="mb-2">Task</h6>
                                <input
                                    placeholder="Add Task"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] text-capitalize "
                                    type="text"
                                    name="title"
                                    value={modalRequest?.task?.title}
                                    readOnly
                                />
                            </Col>
                        </Row>
                        <Row className=''>
                            <Col xs={4}>
                                <h6 className="mb-2">Start Time</h6>
                                <input
                                    placeholder="Task"
                                    className="h-10 w-full rounded-lg border p-[6px] !border-[#cfcbcb]"
                                    type="time"
                                    name="start_time"
                                    value={modalRequest.start_time}
                                    onChange={(e) => {
                                        setModalRequest((prevData) => ({
                                            ...prevData,
                                            start_time: e.target.value,
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={4}>
                                <h6 className="mb-2">End Time</h6>
                                <input
                                    placeholder="Task"
                                    className="h-10 w-full rounded-lg border p-[6px] !border-[#cfcbcb]"
                                    type="time"
                                    name="end_time"
                                    value={modalRequest.end_time}
                                    onChange={(e) => {
                                        const endTime = e.target.value;
                                        setModalRequest((prevData) => ({
                                            ...prevData,
                                            end_time: endTime,
                                        }));
                                    }}
                                />
                            </Col>

                            <Col xs={4}>
                                <h6 className="mb-2">Task Date</h6>
                                <input
                                    placeholder="Task"
                                    className="h-10 w-full rounded-lg border p-[6px] !border-[#cfcbcb]"
                                    type="date"
                                    name="task_date"
                                    value={modalRequest.date}
                                    onChange={(e) => {
                                        setModalRequest({ ...modalRequest, date: e.target.value });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6 className="mb-2">Note</h6>
                                <textarea
                                    placeholder="Add description"
                                    className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-20"
                                    type="text"
                                    name="note"
                                    value={modalRequest.note}
                                    readOnly
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6 className="mb-2">Reason</h6>
                                <textarea
                                    placeholder="Add Reason"
                                    className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-20"
                                    type="text"
                                    name="reason"
                                    value={modalRequest.reason}
                                    onChange={(e) => {
                                        setModalRequest(() => {
                                            return { ...modalRequest, reason: e.target.value }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='text-right'>
                                <button className='border-1 hover:border-2 border-black text-black rounded-md py-2.5 px-3.5 font-medium'
                                    onClick={() => {
                                        handleModalSubmit(false);
                                    }}
                                >Reject</button>
                                <button className='ml-2 border-1 border-[#f47e53] text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                                    onClick={() => {
                                        handleModalSubmit(true);
                                    }}
                                >Accept</button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>

        </div>
    )
}

export default TasksRequest