import { Avatar } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { ReactComponent as CloseEye } from "../../../../src/components/assets/eye-icon-close.svg";
import { ReactComponent as OpenEye } from "../../../../src/components/assets/eye-icon-open.svg";
import { boundaryContext } from '../../../App';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest, patchRequest, postRequest } from "../../../components/axiosClient";
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { ADD_USER_REVIEW, GET_INBOX_LIST, REVIEW_VERIFY_PASSWORD, UPDATE_NOTIFICATION_FLAG } from '../../../components/utils';
import Modal from '../Modal/Modal';
import { Col, Row } from 'react-bootstrap';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

dayjs.extend(relativeTime);

const InboxAction = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);

    const [ratingData, setRatingData] = useState({
        user_id: null,
        month: 0,
        year: 2000,
        inbox_id: null,
        productivity: 5,
        code_quality: 5,
        work_ethics: 5,
        communication: 5,
        user_name: '',
    })
    const [actionData, setActionData] = useState([]);
    const { socket, inboxCount, setInboxCount, setMyTeamActiveRoute, setMyTaskActiveRoute, userData, role } = useContext(boundaryContext);

    useEffect(() => {
        // Fetch data on initial render
        fetchActionData();
    }, []);

    const children = [
        <ToggleButton value={1} key="1" sx={{ px: 2 }}>
            1
        </ToggleButton>,
        <ToggleButton value={2} key="2" sx={{ px: 2 }}>
            2
        </ToggleButton>,
        <ToggleButton value={3} key="3" sx={{ px: 2 }}>
            3
        </ToggleButton>,
        <ToggleButton value={4} key="4" sx={{ px: 2 }}>
            4
        </ToggleButton>,
        <ToggleButton value={5} key="5" sx={{ px: 2 }}>
            5
        </ToggleButton>,
        <ToggleButton value={6} key="6" sx={{ px: 2 }}>
            6
        </ToggleButton>,
        <ToggleButton value={7} key="7" sx={{ px: 2 }}>
            7
        </ToggleButton>,
        <ToggleButton value={8} key="8" sx={{ px: 2 }}>
            8
        </ToggleButton>,
        <ToggleButton value={9} key="9" sx={{ px: 2 }}>
            9
        </ToggleButton>,
        <ToggleButton value={10} key="10" sx={{ px: 2 }}>
            10
        </ToggleButton>,
    ];

    const handlePasswordSubmit = async () => {
        if (password) {
            await checkPassword(password);
        } else {
            errorNotification("Enter password please");
        }
    };

    const checkPassword = async (password) => {
        try {
            const payload = {
                password: password
            };
            const res = await postRequest(REVIEW_VERIFY_PASSWORD, payload, navigate);
            if (res?.type === 1) {
                setPasswordVerified(true);
                setShowPasswordModal(false);
                setPassword('');
            } else {
                errorNotification(res.errormessage);
            }
        } catch {
            errorNotification("Invalid password");
        }
    }

    const handleModalClose = () => {
        setPassword('');
        setShowPasswordModal(false);
    };

    const handleReviewModalClose = () => {
        setPasswordVerified(false);
        setPassword('');
    }

    useEffect(() => {

        if (socket) {
            // Emit the 'getNotification' event
            socket.emit('getNotification');

            // Listen for real-time data and handle the received message
            socket.on('getNotification', (message) => {
                setActionData((prevData) => [message, ...prevData]);
            });
        }

        // Cleanup on component unmount
        return () => {
            if (socket) {
                socket.off('getNotification');
            }
        };
    }, [socket]);



    const fetchActionData = async () => {
        setLoading(true);
        try {
            let API_URL = `${GET_INBOX_LIST}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data) {
                setActionData(res.data.data);
            } else {
                errorNotification(res.message);
            }
        } catch (e) {
            console.error('Failed to fetch action data', e);
            errorNotification(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const formatDate = (date) => {
        const now = dayjs();             // Current time
        const createdAt = dayjs(date);   // Notification time

        // Calculate the difference in hours and minutes
        const diffInMinutes = now.diff(createdAt, 'minute');
        const diffInHours = Math.floor(diffInMinutes / 60);
        const remainingMinutes = diffInMinutes % 60;

        // Check if the date is from today
        if (createdAt.isToday()) {
            if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`; // Show minutes if less than 1 hour
            }
            if (diffInHours >= 1) {
                // Show "x hours y minutes ago" if more than 1 hour
                return `${diffInHours} hour${diffInHours === 1 ? '' : 's'}${remainingMinutes > 0 ? ` ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}` : ''} ago`;
            }
        }

        // If it's not from today, show the date in "hh:mm A, DD MMM" format
        return createdAt.format('hh:mm A, DD MMM'); // e.g., "09:15 AM, 13 Jun"
    };

    const subjectFormatter = (item) => {

        if (item?.type == 'task_assigned') {
            if (item?.task?.title) {
                return `Assigned you on ${item.task.title}`;
            }
            return `Assigned you on task`;
        } else if (item?.type === 'manual_timer_request') {
            return `Request for task manual hours addition`;
        } else if (item?.type === 'timer_regularization') {
            return `Request for task timer regularization`
        } else if (item?.type === 'timer_request_accepted') {
            return `Your Timer request accepted`;
        } else if (item?.type === 'timer_request_rejected') {
            return `Your Timer request rejected`;
        } else if (item?.type === 'added_in_project') {
            return `You have been added to project`;
        } else if (item?.type === 'removed_from_project') {
            return `You have been removed from project`;
        } else if (item?.type === 'comment_mention') {
            return `Mentioned you in a comment`;
        } else if (item?.type === 'employee_review') {
            return `Give a review to ${item?.review_user?.first_name + " " + item?.review_user?.last_name} for ${dayjs().month(item?.month - 1).format('MMMM')} month.`;
        } else {
            return 'Mention you';
        }
    }

    const updateNotification = async (id) => {
        try {
            setLoading(true);
            const res = await patchRequest(
                `${UPDATE_NOTIFICATION_FLAG}?notification_id=${id} `,
                {},
                navigate
            );
            if (res.type === 1) {
                return res.type;
            } else {
                errorNotification(res.errormessage);
                return 2;
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }

    }

    const handleRedirect = async (item) => {
        const id = item?.id;

        if (item?.type === 'employee_review') {
            setRatingData({
                ...ratingData,
                user_id: item?.user_id,
                month: item?.month,
                year: item?.year,
                inbox_id: item?.id,
                user_name: item?.review_user?.first_name + " " + item?.review_user?.last_name
            })
            setShowPasswordModal(true);
            return;
        }

        const res = await updateNotification(id);
        if (res === 1 && inboxCount > 0) {
            const newCount = inboxCount - 1;
            setInboxCount(newCount);
        }
        if (item?.type === 'task_assigned' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                navigate(`/project-list/project-details/task-details/${item?.task?.id}?project_id=${item?.task?.project_id}`);
            } else if (item?.task?.task_type === 'Intern' || item?.task?.task_type === 'Manual') {
                if (role === 'Intern') {
                    navigate(`/My-Training/task-details/${item?.task?.id}`);
                } else if (userData.id === item.task?.task_owner_id) {
                    navigate(`/my-task/task-details/${item?.task?.id}`);
                } else {
                    navigate(`/my-team/my-team-task-details/${item?.task?.id}?employee_id=${item?.sender?.id}`);
                }
            }
            else {
                navigate(`/my-task/task-details/${item?.task?.id}`);
            }
        } else if (item?.type === 'manual_timer_request' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                secureLocalStorage.setItem("activeTab", "task-request");
                navigate(`/project-list/project-details/${item?.task?.project_id}`)
            } else if (item?.task?.task_type === 'Intern' || item?.task?.task_type === 'Manual') {
                setMyTeamActiveRoute('task-request');
                navigate(`/my-team/my-team-details/${item?.sender?.id}`);
            }
        } else if (item?.type === 'timer_regularization' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                secureLocalStorage.setItem("activeTab", "task-request");
                navigate(`/project-list/project-details/${item?.task?.project_id}`)
            } else if (item?.task?.task_type === 'Intern' || item?.task?.task_type === 'Manual') {
                setMyTeamActiveRoute('task-request');
                navigate(`/my-team/my-team-details/${item?.sender?.id}`);
            }
        } else if (item?.type === 'timer_request_accepted' || item?.type === 'timer_request_rejected') {
            setMyTaskActiveRoute("Requested");
            navigate(`/my-task`);
        } else if (item?.type === 'added_in_project') {
            secureLocalStorage.setItem("activeTab", "dashboard");
            navigate(`/project-list/project-details/${item?.project_id}`)
        } else if (item?.type === 'removed_from_project') {
            fetchActionData();
        } else if (item?.type === 'comment_mention') {
            if (item?.task?.task_type === 'Project') {
                navigate(`/project-list/project-details/task-details/${item?.task.id}?project_id=${item?.task.project_id}`);
            } else if (item?.task?.task_type === 'Project Lead') {
                navigate(`/project-lead/project-lead-details/${item?.task?.id}`);
            } else if (item?.task?.task_type === 'Manual') {
                if (role === 'Intern') {
                    navigate(`/My-Training/task-details/${item?.task?.id}`);
                } else if (userData.id === item.task?.task_owner_id) {
                    navigate(`/my-task/task-details/${item?.task?.id}`);
                } else {
                    navigate(`/my-team/my-team-task-details/${item?.task?.id}?employee_id=${item?.sender.id}`);
                }
            } else if (item?.task?.task_type === 'Intern') {
                if (userData.id === item.task?.intern_id) {
                    navigate(`/My-Training/task-details/${item?.task?.id}`);
                } else {
                    navigate(`/my-team/my-team-task-details/${item?.task?.id}?employee_id=${item?.sender.id}`);
                }
            }
        } else if (item?.type === 'employee_review') {
            setShowPasswordModal(true);
        }
        else {
            fetchActionData();
        }
    };

    const addReview = async () => {
        if (ratingData?.note === '' || ratingData?.note === null || ratingData?.note === undefined) {
            errorNotification('Please enter a review note.');
            return;
        }
        const payload = {
            inbox_id: ratingData.inbox_id,
            productivity: ratingData.productivity,
            code_quality: ratingData.code_quality,
            work_ethics: ratingData.work_ethics,
            communication: ratingData.communication,
            note: ratingData.note,
        };

        try {
            setLoading(true);
            const res = await postRequest(
                `${ADD_USER_REVIEW}`,
                payload,
                navigate
            );
            if (res.type === 1) {
                successNotification('Review added successfully.');
                setShowPasswordModal(false);
                setPasswordVerified(false);
                setPassword('');
                fetchActionData();
                if (inboxCount > 0) {
                    const newCount = inboxCount - 1;
                    setInboxCount(newCount);
                }
            } else {
                errorNotification(res.errormessage);
                setPasswordVerified(false);
            }
            setRatingData({
                user_id: null,
                month: 0,
                year: 2000,
                inbox_id: null,
                productivity: 5,
                code_quality: 5,
                work_ethics: 5,
                communication: 5,
                note: '',
                user_name: '',
            });
        } catch (err) {
            errorNotification(err);
        } finally {
            setLoading(false);
        }
    }

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col p-2 overflow-y-auto'>
            {actionData?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='h-full'>
                    <table className='table w-full '>
                        <thead>
                            <tr className=''>
                                <th className='!border-b-[#EFEFEF] font-medium '>From</th>
                                <th className='!border-b-[#EFEFEF] font-medium '>Subject</th>
                                <th className='!border-b-[#EFEFEF] font-medium '>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                actionData.map((item, index) => (
                                    <tr key={index} className='hover:bg-[#f7f7f7] hover:rounded-lg overflow-hidden cursor-pointer'
                                        onClick={(e) => {
                                            if (window.getSelection().toString() === '') {
                                                handleRedirect(item);
                                            }
                                        }}
                                    >
                                        <td className='!border-b-[#EFEFEF] align-middle'>
                                            <div className='flex justify-start items-center gap-2'>
                                                <Avatar className='h-8 w-8 border border-1' src={item?.sender?.profile_image} />
                                                {
                                                    item?.sender ?
                                                        <span>{(item?.sender?.first_name + ' ' + item?.sender?.last_name) || '--'}</span>
                                                        :
                                                        <span>
                                                            Auto Generated
                                                        </span>

                                                }
                                            </div>
                                        </td>
                                        <td className='!border-b-[#EFEFEF] align-middle'>{item?.type ? subjectFormatter(item) : '--'}</td>
                                        <td className='!border-b-[#EFEFEF] align-middle'>{item?.createdAt ? formatDate(item?.createdAt) : '--'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}

            <Modal
                className=""
                title={"Verify Your self"}
                onClose={handleModalClose}
                show={showPasswordModal}
            >
                <div className='p-2'>
                    <div className='mb-4'>
                        <h6 className="mb-2 ml-1">PassWord</h6>
                        <input
                            placeholder="Enter your password"
                            className="h-10 w-full rounded-lg border p-[6px] relative"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            onClick={() => { setShowPassword(!showPassword) }}
                            className="absolute right-8 top-[calc(50%_-_18px)] transform -translate-y-1/2 h-[18px] w-[18px] cursor-pointer"
                        >
                            {!showPassword ? (
                                <CloseEye />
                            ) : (
                                <OpenEye />
                            )}
                        </span>
                    </div>
                    <div className="text-right">
                        <button className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                            onClick={handlePasswordSubmit}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                className=""
                title={"Add review"}
                onClose={handleReviewModalClose}
                show={passwordVerified}
            >
                <div className='modal-body p-2'>
                    <Row className='pb-4'>
                        <Col xs={9}>
                            <div className='flex flex-col justify-start items-start gap-1'>
                                <h6 className="mb-2">Name</h6>
                                <input
                                    type="text"
                                    className='h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]'
                                    placeholder='Month'
                                    value={ratingData.user_name}
                                    disabled
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-4'>
                        <Col xs={6}>
                            <div className='flex flex-col justify-start items-start gap-1'>
                                <h6 className="mb-2">Month</h6>
                                <input
                                    type="text"
                                    className='h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]'
                                    placeholder='Month'
                                    value={dayjs().month(ratingData.month - 1).format('MMMM')}
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='flex flex-col justify-start items-start gap-1'>
                                <h6 className="mb-2">Year</h6>
                                <input
                                    type="text"
                                    className='h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]'
                                    placeholder='Month'
                                    value={ratingData.year}
                                    disabled
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-4'>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <h6 className="mb-2">Code Quality</h6>
                            <ToggleButtonGroup
                                size="small"
                                value={ratingData.code_quality}
                                exclusive
                                onChange={(e, newVal) => newVal !== null && setRatingData({ ...ratingData, code_quality: newVal })}
                                aria-label="Code Quality"
                            >
                                {children}
                            </ToggleButtonGroup>
                        </div>
                    </Row>
                    <Row className='pb-4'>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <h6 className="mb-2">Productivity</h6>
                            <ToggleButtonGroup
                                size="small"
                                value={ratingData.productivity}
                                exclusive
                                onChange={(e, newVal) => newVal !== null && setRatingData({ ...ratingData, productivity: newVal })}
                                aria-label="Code Quality"
                            >
                                {children}
                            </ToggleButtonGroup>
                        </div>
                    </Row>
                    <Row className='pb-4'>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <h6 className="mb-2">Work Ethics</h6>
                            <ToggleButtonGroup
                                size="small"
                                value={ratingData.work_ethics}
                                exclusive
                                onChange={(e, newVal) => newVal !== null && setRatingData({ ...ratingData, work_ethics: newVal })}
                                aria-label="Code Quality"
                            >
                                {children}
                            </ToggleButtonGroup>
                        </div>
                    </Row>
                    <Row className='pb-4'>
                        <div className='flex flex-col justify-start items-start gap-1'>
                            <h6 className="mb-2">Communication</h6>
                            <ToggleButtonGroup
                                size="small"
                                value={ratingData.communication}
                                exclusive
                                onChange={(e, newVal) => newVal !== null && setRatingData({ ...ratingData, communication: newVal })}
                                aria-label="Code Quality"
                            >
                                {children}
                            </ToggleButtonGroup>
                        </div>
                    </Row>

                    <Row className='pb-4'>
                        <Col xs={12}>
                            <div className='flex flex-col justify-start items-start gap-1'>
                                <h6 className="mb-2">Note</h6>
                                <textarea
                                    name="note"
                                    type='text-area'
                                    placeholder='Enter note'
                                    className='min-h-20 w-full rounded-lg border !border-[#cfcbcb] p-[6px]'
                                    value={ratingData.note}
                                    onChange={(e) => setRatingData({ ...ratingData, note: e.target.value })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} className="text-right">
                            <button className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                                onClick={() => { addReview() }}
                            >
                                Add Review
                            </button>
                        </Col>
                    </Row>
                </div>

            </Modal>

            <style>
                {
                    `
                        .modal-content{
                            width:550px;
                        }
                    `
                }
            </style>
        </div>

    )
}

export default InboxAction