import { Avatar } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getRequest } from "../../../components/axiosClient";
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { GET_ARCHIVE_INBOX_LIST } from '../../../components/utils';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";

dayjs.extend(relativeTime);

const InboxArchive = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageInformation, setPageInformation] = useState({});
  const pageSize = 30;

  const [archiveData, setArchiveData] = useState([]);

  const [hasMoreData, setHasMoreData] = useState(true);
  const isMounted = useRef(false);
  const listRef = useRef();

  useEffect(() => {
    if (!isMounted.current) {
      fetchArchiveData();
      isMounted.current = true;
    }
  }, [])

  const fetchArchiveData = async () => {
    setLoading(true);
    try {
      let API_URL = `${GET_ARCHIVE_INBOX_LIST}?page=${1}&limit=${pageSize}`;
      const res = await getRequest(API_URL, null, navigate);
      if (res.data && res.data.data) {
        setArchiveData(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
        setPage(2);
      } else {
        errorNotification(res.message);
      }
    } catch (e) {
      console.error('Failed to fetch action data', e);
      setHasMoreData(false);
      errorNotification(e.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const fetchData = useCallback(async () => {
    if (loading || pageInformation?.current_page >= pageInformation?.last_page) return;

    setLoading(true);
    try {
      let apiUrl = `${GET_ARCHIVE_INBOX_LIST}?page=${page}&limit=${pageSize}`;
      const res = await getRequest(apiUrl, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        const newPageData = res.data.data.page_data;
        setArchiveData((preData) => [...preData, ...newPageData]);
        setPageInformation(res.data.data.page_information);
        setPage((prevPage) => prevPage + 1);
      }
      if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
        setHasMoreData(false); // No more data available
      }

    } catch (error) {
      errorNotification(error);
    } finally {
      setLoading(false); // Stop loading
    }
  }, [page, loading, hasMoreData]);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 200) {
        if (!loading) {
          fetchData();
        }
      }
    }
  };

  useEffect(() => {
    const divElement = listRef.current;
    let debounceTimeout;

    const handleDebouncedScroll = () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(handleScroll, 200);
    };

    if (divElement) {
      divElement.addEventListener("scroll", handleDebouncedScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleDebouncedScroll);
      }
      clearTimeout(debounceTimeout);
    };
  }, [fetchData, loading]);



  const formatDate = (date) => {
    const now = dayjs();             // Current time
    const createdAt = dayjs(date);   // Notification time

    // Calculate the difference in hours and minutes
    const diffInMinutes = now.diff(createdAt, 'minute');
    const diffInHours = Math.floor(diffInMinutes / 60);
    const remainingMinutes = diffInMinutes % 60;

    // Check if the date is from today
    if (createdAt.isToday()) {
      if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`; // Show minutes if less than 1 hour
      }
      if (diffInHours >= 1) {
        // Show "x hours y minutes ago" if more than 1 hour
        return `${diffInHours} hour${diffInHours === 1 ? '' : 's'}${remainingMinutes > 0 ? ` ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}` : ''} ago`;
      }
    }

    // If it's not from today, show the date in "hh:mm A, DD MMM" format
    return createdAt.format('hh:mm A, DD MMM'); // e.g., "09:15 AM, 13 Jun"
  };

  const subjectFormatter = (item) => {

    if (item?.type == 'task_assigned') {
      if (item?.task.title) {
        return `Assigned you on ${item.task.title}`;
      }
      return `Assigned you on task`;
    } else if (item?.type === 'manual_timer_request') {
      return `Request for task manual hours addition`;
    } else if (item?.type === 'timer_regularization') {
      return `Request for task timer regularization`
    } else if (item?.type === 'timer_request_accepted') {
      return `Your Timer request accepted`;
    } else if (item?.type === 'timer_request_rejected') {
      return `Your Timer request rejected`;
    } else if (item?.type === 'added_in_project') {
      return `You have been added to project`;
    } else if (item?.type === 'removed_from_project') {
      return `You have been removed from project`;
    } else if (item?.type === 'comment_mention') {
      return `Mentioned you in a comment`;
    } else if (item?.type === 'employee_review') {
      return `Review for ${item?.review_user?.first_name + " " + item?.review_user?.last_name} for the month of ${dayjs().month(item?.month - 1).format('MMMM')} has been added.`;
    } else {
      return 'Mention you';
    }
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className='h-full flex flex-col p-2 pb-3 overflow-y-auto' ref={listRef}>
      {archiveData?.length === 0 ? (
        <div className="no__transcation">
          <img src={noDataFound} alt={noDataFound} loading="lazy" />
        </div>
      ) : (
        <div className='h-full'>
          <table className='table w-full'>
            <thead>
              <tr className=''>
                <th className='!border-b-[#EFEFEF] font-medium '>From</th>
                <th className='!border-b-[#EFEFEF] font-medium '>Subject</th>
                <th className='!border-b-[#EFEFEF] font-medium '>Time</th>
              </tr>
            </thead>
            <tbody>
              {
                archiveData.map((item, index) => (
                  <tr key={index} className='overflow-hidden cursor-pointer'>
                    <td className='!border-b-[#EFEFEF] align-middle'>
                      <div className='flex justify-start items-center gap-2'>
                        <Avatar className='h-8 w-8 border border-1' src={item?.sender?.profile_image} />
                        {
                          item?.sender ?
                            <span>{(item?.sender?.first_name + ' ' + item?.sender?.last_name) || '--'}</span>
                            :
                            <span>
                              Auto Generated
                            </span>

                        }
                      </div>
                    </td>
                    <td className='!border-b-[#EFEFEF] align-middle'>{item?.type ? subjectFormatter(item) : '--'}</td>
                    <td className='!border-b-[#EFEFEF] align-middle'>{item?.createdAt ? formatDate(item?.createdAt) : '--'}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )}
    </div>

  )
}

export default InboxArchive