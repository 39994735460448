import { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { json, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import validator from "validator";
import { boundaryContext } from "../../../App";
import eyeCloseIcon from "../../assets/eyeCloseIconLogin.svg";
import eyeOpenIcon from "../../assets/eyeOpenIconLogin.svg";
import loginMain from "../../assets/loginBgImage.png";
import mailIcon from "../../assets/loginMailIcon.svg";
import passwordIcon from "../../assets/loginPasswordIcon.svg";
import securr from "../../assets/unikwork-logo3x.png";
import { postRequest } from "../../axiosClient";
import {
  ADMIN_DASHBOARD,
  COMPANY_AUDIT,
  COMPANY_DASHBOARD,
  DASHBOARD,
  FORGETPASSWORD,
} from "../../constans/Route";
import {
  errorNotification,
  successNotification,
} from "../../toast-notification/common-toast";
import {
  GET_ADMIN_PROFILE,
  GET_COMPANY_PROFILE,
  GET_HUNTER_PROFILE,
  GET_MEMBER_PROFILE,
  GET_PROFILE,
  LOGIN,
  LOGOUT,
  SITE_KEY,
} from "../../utils";
import "./Login.css";
import axios from "axios";

const Login = (props) => {
  const { theme } = props;
  let navigate = useNavigate();
  const { setIsBounty, setIsPremium, setIsAudit } = useContext(boundaryContext);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [captchError, setCaptchaError] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [isCaptchaShow, setIsCaptchaShow] = useState(false);
  const captchaRef = useRef(null);

  // For hide and show password
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // For take input of password
  const handlePasswordChange = (prop) => (event) => {
    setLoginError("");
    setCaptchaError("");
    setValues({ ...values, [prop]: event?.target?.value });
  };

  // login api call
  const handlePlatformLogin = async (payload) => {
    setLoading(true);
    try {
      const res = await postRequest(LOGIN, payload, navigate);
      if (res?.type === 1) {
        if (res?.response?.data?.success) {
          handleGetRoleAndLogon(res.response?.data);
          setLoading(true);
        } else {
          errorNotification(res?.response?.data?.message);
          setLoading(false);
          captchaRef?.current?.reset();
        }
      }
      if (res?.type === 2) {
        captchaRef?.current?.reset();

        if (
          res?.errormessage ===
          "Too many login requests associated with your account" ||
          res?.errormessage === "You must confirm you are not a robot" ||
          res?.errormessage === "Unauthorized user, Please try again"
        ) {
          setIsCaptchaShow(true);
        } else {
          setLoginError(res?.errormessage || res?.errors);
        }
        setLoading(false);
        // errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      setLoginError(error?.message);
      setLoading(false);
      // errorNotification(error.message);
    }
  };

  const handleGetRoleAndLogon = async (loginResponse) => {
    // const getrole = "admin"
    const getrole = await loginResponse?.data?.role?.name;
    const Token = await loginResponse.data?.token;
    const role_accesses = await loginResponse?.data?.role?.role_accesses;
    const API_URL = GET_PROFILE;
    // getrole === "member" ? GET_MEMBER_PROFILE : getrole === "company" ? GET_COMPANY_PROFILE : getrole === "hunter" ? GET_HUNTER_PROFILE : GET_ADMIN_PROFILE;
    setLoading(true);
    await axios
      .get(API_URL, { headers: { Authorization: Token } })
      .then((res) => {
        successNotification("Sign in successfully");
        secureLocalStorage.setItem("role", getrole);
        secureLocalStorage.setItem("token", Token);
        secureLocalStorage.setItem(
          "role_accesses",
          JSON.stringify(role_accesses)
        );
        secureLocalStorage.setItem("roleid", res?.data?.data?.role?.id);
        // secureLocalStorage.setItem("username", res?.data?.data?.username || res?.data?.data?.company_name || res?.data?.data?.name)
        if (getrole) {
          navigate(ADMIN_DASHBOARD);
        }
        setLoading(false);
      })
      .catch((error) => {

        errorNotification(error.response.data.message);
        setLoading(false);
      });
  };

  // For validation and login user
  const handleLogin = (e) => {
    e.preventDefault();

    let token = isCaptchaShow ? captchaRef.current.getValue() : "";
    if (validator.isEmpty(email)) {
      setLoginError("Please enter correct email and password");
    } else if (!validator.isEmail(email)) {
      setLoginError("Please enter correct email and password");
    } else if (validator.isEmpty(values.password)) {
      setLoginError("Please enter correct email and password");
    } else if (isCaptchaShow && !token) {
      setCaptchaError("You must confirm you are not a robot");
    } else if (!isCaptchaShow) {
      const loginData = {
        email: email?.toLowerCase(),
        password: values.password,
      };
      handlePlatformLogin(loginData);
    } else if (isCaptchaShow && token) {
      const loginData = {
        email: email?.toLowerCase(),
        password: values.password,
        captcha_token: token,
      };
      handlePlatformLogin(loginData);
    }
  };

  return (
    <>
      <div className="grid  grid-cols-2 h-[100vh] w-[100vw] ">
        <div className=" hidden lg:block  p-3 bg-[#fff0d]">
          <div className=" bg-[##e0e0e0] h-[100%] w-[100%] rounded-[20px] flex justify-center items-center">
            <img src={loginMain} alt="login_image" />
          </div>
        </div>
        <div className="bg-[#fff] flex flex-col col-span-2 lg:col-span-1 justify-evenly items-center">
          <div className="flex justify-center pt-4">
            <img src={securr} className="h-[34px]" alt="securr" />
          </div>
          <div
            className="grid grid-cols-5 w-[100%] px-5 xl:px-0"
            style={loading ? { pointerEvents: "none", opacity: "0.7" } : {}}
          >
            <div className="col-span-1 lg:col-span-0 xl:col-span-1"></div>
            <div className="col-span-3 lg:col-span-5 xl:col-span-3">
              <form onSubmit={handleLogin}>
                <h1 className="text-[#292929] text-[44px] font-[600] interFontFamily">
                  Sign in
                </h1>
                <span className="text-[#292929] text-[14px] font-[400] interFontFamily pt-1">
                  Please enter your login Details below
                </span>
                <div className="pt-5">
                  <span className="text-[#1A1D1F] text-[16px] font-[600] interFontFamily">
                    Email Address
                  </span>
                  <div className="relative bg-[#F4F4F4] rounded-[12px] mt-1">
                    <img
                      src={mailIcon}
                      className="top-[25%] h-[20px] left-4 absolute"
                      alt="searchIcon "
                    />
                    <input
                      type="email"
                      value={email.toLowerCase()}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setLoginError("");
                        setCaptchaError("");
                      }}
                      autoComplete="username"
                      placeholder="Your email"
                      className="bg-[#FCFCFC] text-[#000]  focus:bg-[#EFEFEF] interFontFamily rounded-[12px] py-2 text-[16px] 
                    placeholder-[#9A9FA5]-400 w-full px-12 border-[#EFEFEF]  border-[2px] focus:border-[#EFEFEF] hover:cursor-pointer"
                      name=""
                    />
                  </div>
                </div>
                <div className="pt-3">
                  <span className="text-[#1A1D1F] text-[16px] font-[600] interFontFamily ">
                    Password
                  </span>
                  <div className="relative bg-[#F4F4F4] rounded-[12px]  mt-1 ">
                    <img
                      src={passwordIcon}
                      className="top-[25%] h-[20px] left-4 absolute"
                      alt="searchIcon "
                    />
                    <input
                      type={values.showPassword ? "text" : "password"}
                      onChange={handlePasswordChange("password")}
                      value={values.password}
                      autoComplete="current-password"
                      placeholder="Enter Password"
                      className="bg-[#FCFCFC] text-[#000]  focus:bg-[#EFEFEF] interFontFamily rounded-[12px] py-2 text-[16px] 
                    placeholder-[#9A9FA5]-400 w-full px-12 border-[#EFEFEF]  border-[2px] focus:border-[#EFEFEF] hover:cursor-pointer"
                      name=""
                    />
                    <img
                      src={values.showPassword ? eyeOpenIcon : eyeCloseIcon}
                      className="top-[25%] h-[20px] right-4 absolute cursor-pointer"
                      alt="searchIcon "
                      onClick={handleClickShowPassword}
                    />
                  </div>
                </div>
                <div className="login__state">{loginError && loginError}</div>
                {isCaptchaShow && (
                  <div className="mt-4">
                    <ReCAPTCHA
                      sitekey={SITE_KEY}
                      ref={captchaRef}
                      onChange={(token) => {
                        setCaptchaError("");
                        setLoginError("");
                      }}
                    />
                    <div className="login__state">
                      {captchError && captchError}
                    </div>
                  </div>
                )}
                {/* <div className="text-end pt-[7px] ">
                  {" "}
                  <span
                    className="text-[#000]  text-[16px] font-[600] interFontFamily cursor-pointer"
                    onClick={() => navigate(FORGETPASSWORD)}
                  >
                    Forgot Password?
                  </span>
                </div> */}
                <div className="pt-5">
                  <button
                    disabled={loading}
                    className="bg-[#f47e53] text-[#FCFCFC]   
          py-[10px]
          px-[20px]
          rounded-[12px]
          text-[18px] cursor-pointer font-[600] w-[100%]"
                  >
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm  mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    <span>Continue</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-span-0 xl:col-span-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
