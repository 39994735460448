import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../../components/axiosClient';
import { GET_MY_TEAM_REVIEW_BY_ID } from '../../../components/utils';
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import dayjs from "dayjs";

const MyTeamRatingView = () => {

    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { id, month, year } = useParams();

    useEffect(() => {
        getReviewData();
    }, []);

    const getReviewData = async () => {
        try {
            setLoading(true);

            const API_URL = GET_MY_TEAM_REVIEW_BY_ID + `?user_id=${id}&month=${month}&year=${year}`

            const res = await getRequest(API_URL, null, navigate);
            if (res && res.data) {
                setData(res.data.data.userReviews[0]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const goBack = () => {
        navigate(-1);
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col gap-4 p-4 bg-white rounded-xl'>
            <div className="go__back__btn">
                <div className="">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer" />
                </div>
            </div>
            <div className='grow flex flex-col gap-4 px-2'>
                <Row>
                    <Col xs={6} className="flex gap-3">
                        <label>Month:</label>
                        <p>{dayjs().month(data?.month - 1).format('MMMM')}</p>
                    </Col>
                    <Col xs={6} className="flex gap-3">
                        <label>Year:</label>
                        <p>{data?.year}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="flex gap-3">
                        <label>Productivity :</label>
                        <p>{data?.productivity}/10</p>
                    </Col>
                    <Col xs={6} className="flex gap-3">
                        <label>Code Quality:</label>
                        <p>{data?.code_quality}/10</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="flex gap-3">
                        <label>Work Ethics:</label>
                        <p>{data?.work_ethics}/10</p>
                    </Col>
                    <Col xs={6} className="flex gap-3">
                        <label>Communication :</label>
                        <p>{data?.communication}/10</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="flex gap-3">
                        <label>Note:</label>
                        <p>{data?.note}</p>
                    </Col>
                </Row>
            </div>

        </div>
    );
}



export default MyTeamRatingView