import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from "react-select";
import validator from 'validator';
import { boundaryContext } from '../../../../App';
import { ReactComponent as BoardIcon } from '../../../../components/assets/List_view_icon.svg'; // Import your custom SVG icon
import { ReactComponent as ListIcon } from '../../../../components/assets/board_view_icon.svg'; // Import a different icon for checked state
import high from '../../../../components/assets/high_priority.svg';
import low from '../../../../components/assets/low_priority.svg';
import normal from '../../../../components/assets/normal_priority.svg';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../components/axiosClient';
import LoadingSpinner from '../../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../../components/toast-notification/common-toast';
import { CREATE_TASK, DELETE_TASK_BY_ID, GET_PROJECT_MEMBERS, GET_TASKS, UPDATE_TASK } from '../../../../components/utils';
import Modal from '../../Modal/Modal';
import NewModal from '../../Modal/NewModal';
import TaskListKanban from './TaskListKanban';
import TaskListView from './TaskListView';
import { Tooltip } from '@mui/material';

const TaskList = ({ projectId }) => {

    const [firstLoading, setFirstLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [taskData, setTaskData] = useState({
        title: "",
        assigned_to: null,
        due_date: null,
        priority: null,
        description: null,
        project_id: projectId,
        status: "To do",
    });
    const [taskList, setTaskList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [filterMemberList, setFilterMemberList] = useState([]);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { boardView, setBoardView } = useContext(boundaryContext);

    const assignedToFilterParams = searchParams.get('assigned_to');
    const [assignedToFilter, setAssignedToFilter] = useState(assignedToFilterParams ? parseInt(assignedToFilterParams) : null);

    const priorityParams = searchParams.get('priority');
    const [priorityFilter, setPriorityFilter] = useState(priorityParams ? priorityParams : null);

    const taskListRef = useRef(null);

    useEffect(() => {
        getProjectMembers();
    }, [])

    useEffect(() => {
        getTaskList();
    }, [assignedToFilter, priorityFilter])

    const handleTaskView = (id) => {
        navigate(`/project-list/project-details/task-details/${id}?project_id=${projectId}`)
    };

    const handleOpenModal = (id) => {
        let status;
        if (id === '11') {
            status = "To do"
        } else if (id === '12') {
            status = "In progress"
        } else if (id === '13') {
            status = "To be verified"
        } else if (id === '14') {
            status = "Completed"
        } else {
            status = "To do"
        }
        setTaskData({ ...taskData, status: status });
        setIsOpen(true);
    }

    const role_access = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";

    const handleAddTask = () => {
        if (validator.isEmpty(taskData.title)) {
            errorNotification("Task title is required");
        } else {
            addTask(taskData);
            setIsOpen(false);
        }
    }

    const addTask = async (payload) => {
        try {
            const res = await postRequest(CREATE_TASK, payload, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getTaskList();
                    setTaskData({
                        title: "",
                        assigned_to: null,
                        due_date: null,
                        priority: null,
                        description: null,
                        project_id: projectId,
                        status: "To do",
                    })
                    successNotification(res.response.data.message);
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    }

    const getProjectMembers = async () => {
        try {
            setFirstLoading(true);
            const res = await getRequest(
                `${GET_PROJECT_MEMBERS}?project_id=${projectId}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const dataList = res.data.data.map((item) => {
                    return { value: item?.user.id, label: item?.user.first_name + ' ' + item?.user.last_name };
                });
                setFilterMemberList(res.data.data.map((item) => {
                    return { value: item?.user.id, label: item?.user.first_name + ' ' + item?.user.last_name };
                }));
                dataList.unshift({ value: null, label: "None" });
                setMemberList(dataList);
            } else {
                errorNotification(res.errormessage);
                setMemberList([]);
            }
            setFirstLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    }

    const getTaskList = async () => {
        try {
            // setFirstLoading(true);
            let apiUrl = `${GET_TASKS}?project_id=${projectId}`;
            if (assignedToFilter && assignedToFilter !== "All") {
                apiUrl += `&assigned_to=${assignedToFilter}`;
            }
            if (priorityFilter && priorityFilter !== "All") {
                apiUrl += `&priority=${priorityFilter}`;
            }
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setTaskList(res.data.data);
            } else {
                errorNotification(res.errormessage);
                setTaskList([]);
            }
            setFirstLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    }

    const assignToFilterOption = [
        { label: "All", value: "All" },
        ...filterMemberList
    ]

    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };
    const priorityOptionsList = ["Low", "Medium", "High"];
    const priorityOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props,
        icon: customIcons[props]
    }));


    const priorityOptionWithNone = [
        {
            label: "All",
            value: "All",
        },
        ...priorityOption
    ];

    const priorityFilterOption = priorityOptionWithNone.map((props) => (props));

    const [showModal, setShowModal] = useState(false);
    const [deleteTaskId, setDeleteTackId] = useState(null);


    const handleDeleteTask = (id) => {
        if (id) {
            setDeleteTackId(id);
        } else {
            setDeleteTackId(null);
        }
        setShowModal(!showModal);
    }

    const confirmDelete = async () => {
        try {
            if (!deleteTaskId) {
                return;
            }
            let apiUrl = `${DELETE_TASK_BY_ID}?task_id=${deleteTaskId}`;
            const res = await deleteRequest(
                apiUrl,
                null,
                navigate
            );
            if (res?.type === 1) {
                await getTaskList();
                handleDeleteTask();
                successNotification(res.response.data.message);
            } else {
                errorNotification(res.errormessage);
                errorNotification(res.errormessage);
            }
        } catch (error) {
            console.error("error", error);
        }
    }

    const handleTaskComplete = async (id, is_completed) => {
        if (id == null || is_completed == null || id == undefined || is_completed == undefined) {
            return;
        }
        const payload = {
            task_id: id,
            is_completed: !is_completed
        }
        try {
            const res = await patchRequest(
                `${UPDATE_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                // getTaskList();
            } else {
                // getTaskDetails();
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            console.error(error);
            errorNotification(error);
        } finally {
            setFirstLoading(false);
        }
    }

    return firstLoading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col px-2 pb-3 gap-2' ref={taskListRef}>
            <div className={`flex flex-row items-center px-2 ${role_access ? 'justify-between' : 'justify-end'}`}>

                {
                    role_access &&
                    <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                        onClick={() => handleOpenModal()}
                    >
                        + Add Task
                    </button>
                }
                <div className='px-4 flex justify-end items-center gap-4 '>
                    <Tooltip title={boardView ? 'List view' : 'Boards view'}>
                        <button onClick={() => setBoardView(!boardView)} className='border-1 border-[#cccccc] py-[7px] px-2 rounded-md'>
                            {boardView ? <BoardIcon /> : <ListIcon />}
                        </button>
                    </Tooltip>

                    <Select
                        className="basic-single !min-w-48"
                        classNamePrefix="select"
                        placeholder="Assign to"
                        name="Assign to"
                        options={assignToFilterOption}
                        value={assignToFilterOption.find(option => option.value === assignedToFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                assigned_to: selectedOption.value,
                            };
                            setSearchParams(new URLSearchParams(updatedParams));
                            setAssignedToFilter(selectedOption.value);
                        }}
                    />
                    <Select
                        className="basic-single !min-w-36"
                        classNamePrefix="select"
                        placeholder="Priority"
                        name="Priority"
                        options={priorityFilterOption}
                        value={priorityFilterOption.find(option => option.value === priorityFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                priority: selectedOption.value,
                            };
                            setSearchParams(new URLSearchParams(updatedParams));
                            setPriorityFilter(selectedOption.value);
                        }}
                    />
                </div>
            </div>
            <div className='grow overflow-y-auto'>

                {
                    boardView ?
                        <TaskListKanban
                            projectId={projectId}
                            taskList={taskList}
                            handleTaskComplete={handleTaskComplete}
                            getTasks={getTaskList}
                            handleOpenModal={handleOpenModal}
                        />
                        :
                        <TaskListView
                            projectId={projectId}
                            handleTaskView={handleTaskView}
                            handleTaskComplete={handleTaskComplete}
                            taskList={taskList}
                            getTasks={getTaskList}

                        />
                }
            </div>
            <Modal
                className="min-w-[60vw] task-modal"
                title="Add task"
                onClose={() => setIsOpen(false)}
                show={isOpen}
            >
                <div className='modal-body flex flex-col gap-4' >
                    <Row className=''>
                        <Col xs={12}>
                            <h6 className="mb-2">Task</h6>
                            <input
                                placeholder="Add Task"
                                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                type="text"
                                name="title"
                                value={taskData.title}
                                autoComplete="off"
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <h6 className="mb-2">Assignee to</h6>
                            <Select className="rounded-lg text-capitalize"
                                options={memberList}
                                name="assignee to"
                                value={memberList.find(option => option.value === taskData.assigned_id)}
                                onChange={(selectedOption) =>
                                    setTaskData({ ...taskData, assigned_to: selectedOption ? selectedOption.value : null })
                                }
                            />
                        </Col>
                        <Col xs={4}>
                            <h6 className="mb-2">Priority</h6>
                            <Select
                                className="rounded-lg text-capitalize"
                                options={priorityOption}
                                formatOptionLabel={customFormatOptionLabel}
                                value={taskData.priority ?
                                    { label: taskData.priority, value: taskData.priority, icon: customIcons[taskData.priority] } : null}
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        priority: e.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <h6 className="mb-2">Due Date</h6>
                            <input
                                placeholder="Due Date"
                                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                type="date"
                                name="due_date"
                                value={taskData.due_date}
                                autoComplete="off"
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        due_date: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-2">Description</h6>
                            <textarea
                                placeholder="Add description"
                                className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-40"
                                type="text"
                                name="task"
                                value={taskData.description}
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        description: e.target.value,
                                    });
                                }}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                                onClick={handleAddTask}
                            >Save</button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <NewModal
                title="Are you Sure?"
                onClose={() => handleDeleteTask()}
                show={showModal}
            >
                <div className="modal-body add__cmp__form">
                    <h6>Do you really want to delete this task?</h6>
                    <div className="add__close__section">
                        <button
                            type="button"
                            className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
                            onClick={() => handleDeleteTask()}
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
                            onClick={confirmDelete}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </NewModal>
            <style>
                {`
                    .task-modal .modal-content{
                        overflow-y : unset;
                    }

                    .css-13cymwt-control{
                        border-color: var(--select-border);    
                    }
                `}
            </style>
        </div>
    )
}

export default TaskList