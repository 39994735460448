import axios from 'axios';
import { errorNotification } from './toast-notification/common-toast';
import secureLocalStorage from "react-secure-storage";


const axiosClient = axios.create();
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // Authorization: getToken(),
    // Authorization: secureLocalStorage.getItem("token")
};

export function unAuthorized(navigate) {
    navigate("/")
    secureLocalStorage.clear();
}
export async function getRequest(URL, params, navigate) {
    axiosClient.defaults.params = params
    axiosClient.defaults.headers.Authorization = secureLocalStorage.getItem("token");
    return axiosClient
        .get(URL)
        .then((response) => response)
        .catch((error) => {
            if (error?.response?.status === 401) {
                errorNotification(error?.response?.data?.message)
                unAuthorized(navigate)
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });;
}

export async function postRequest(URL, payload, navigate) {
    axiosClient.defaults.headers.Authorization = secureLocalStorage.getItem("token");
    // axiosClient.defaults.headers.common['Access-Control-Allow-Origin']= "*";
    axiosClient.defaults.maxBodyLength = Infinity
    return axiosClient
        .post(URL, payload)
        .then((response) => {
            return { type: 1, response };
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                errorNotification(error?.response?.data?.message)
                unAuthorized(navigate)
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

// export function putRequest(URL, payload, navigate) {
//     axiosClient.defaults.headers.Authorization = getToken();
//     return axiosClient
//         .put(`/${URL}`, payload)
//         .then((response) => {
//             return { type: 1, response };
//         })
//         .catch((error) => {
//             if (error.response.status === 401) {
//                 errorNotification(error?.response?.data?.message)
//                 unAuthorized(navigate)
//             }
//             const errors = error?.message;
//             const errormessage = error?.response?.data?.message;
//             return { type: 2, errors, errormessage };
//         });
// }

export async function patchRequest(URL, payload, navigate) {
    // axiosClient.defaults.params = params
    axiosClient.defaults.headers.Authorization = secureLocalStorage.getItem("token");
    return axiosClient
        .patch(URL, payload)
        .then((response) => {
            return { type: 1, response };
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                errorNotification(error?.response?.data?.message)
                unAuthorized(navigate)
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

export async function deleteRequest(URL, navigate) {
    axiosClient.defaults.headers.Authorization = secureLocalStorage.getItem("token");
    return axiosClient
        .delete(URL)
        .then((response) => { return { type: 1, response } })
        .catch((error) => {
            if (error.response.status === 401) {
                unAuthorized(navigate)
                // navigate("/")
                // secureLocalStorage.clear()
                // errorNotification(error?.response?.data?.message)

            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}


