import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import noDataFound from "../../../../components/assets/No data-rafiki 1.svg";
import { getRequest, patchRequest } from '../../../../components/axiosClient';
import { errorNotification } from '../../../../components/toast-notification/common-toast';
import { GET_TASKS, UPDATE_PROJECT_TASKS_INDEX, UPDATE_TASK } from '../../../../components/utils';
import TaskCard from './TaskCard';
import Select from "react-select";

// import { Select, MenuItem } from '@mui/material'; // MUI for dropdown

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scroll */
`;


const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
  ${'' /* height : calc(100vh - 350px) !important; */}
  overflow-y: auto;
`;

const TaskColumnStyles = styled.div`
  display: flex;
  width: 100%;
`;





const TaskListKanban = ({ projectId, handleTaskComplete, taskList, getTasks, handleOpenModal }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [taskList, setTaskList] = useState([]);
    const [columns, setColumns] = useState({});


    const containerRef = useRef(null);

    const handleAutoScroll = (clientX) => {
        const scrollAmount = 20; // Scroll speed
        const container = containerRef.current;

        if (!container) return;

        const { left, right } = container.getBoundingClientRect();

        if (clientX < left + 50) {
            // Scroll left when near the left edge
            container.scrollLeft -= scrollAmount;
        } else if (clientX > right - 50) {
            // Scroll right when near the right edge
            container.scrollLeft += scrollAmount;
        }
    };

    const onDragUpdate = (update) => {
        if (update?.draggableProps?.style) {
            const { clientX } = update.draggableProps.style;
            handleAutoScroll(clientX);
        }
    };
    useEffect(() => {
        getTaskList();
    }, [taskList]);

    useEffect(() => {
        const handleDrag = (event) => {
            handleAutoScroll(event); // Reference to the auto-scroll function
        };

        document.addEventListener('drag', handleDrag);

        // Cleanup the listener on component unmount
        return () => {
            document.removeEventListener('drag', handleDrag);
        };
    }, [handleAutoScroll]); // Include the handleAutoScroll function as a dependency




    const getTaskList = async () => {
        if (taskList && taskList.length > 0) {
            const columnsFromBackend = {
                "11": { title: 'To do', items: taskList.filter(task => task.status === 'To do') },
                "12": { title: 'In progress', items: taskList.filter(task => task.status === 'In progress') },
                "13": { title: 'To be verified', items: taskList.filter(task => task.status === 'To be verified') },
                "14": { title: 'Completed', items: taskList.filter(task => task.status === 'Completed') },
            };
            setColumns(columnsFromBackend);
        }
    };

    const updateTaskStatus = async (payload) => {
        setLoading(true);

        try {
            const res = await patchRequest(
                `${UPDATE_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                return true;
                // successNotification(res.response.data.message);
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const updateIndexOfTask = async (data) => {
        const payload = {
            project_id: projectId,
            tasks: data
        }
        setLoading(true);
        try {
            const res = await patchRequest(
                `${UPDATE_PROJECT_TASKS_INDEX}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }

    }


    const onDragEnd = async (result) => {

        if (!result.destination) return;

        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
            const updatedColumnsData = {
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            }

            const updatedTask = {
                task_id: removed.id.toString(),
                status: columns[destination.droppableId].title,
            }
            const res = await updateTaskStatus(updatedTask);
            if (res) {
                const transformedData = Object.keys(updatedColumnsData).map((key) => {
                    const statusObj = updatedColumnsData[key];
                    return {
                        title: statusObj.title || statusObj.items[0]?.status, // Use title or extract from items
                        taskIds: statusObj.items.map((task) => task.id), // Create array of task IDs
                    };
                });
                await updateIndexOfTask(transformedData);
                await getTasks();
            }
        } else {
            // Handle moving within the same column
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
            const updatedColumnsData = {
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            }
            const transformedData = Object.keys(updatedColumnsData).map((key) => {
                const statusObj = updatedColumnsData[key];
                return {
                    title: statusObj.title || statusObj.items[0]?.status, // Use title or extract from items
                    taskIds: statusObj.items.map((task) => task.id), // Create array of task IDs
                };
            });
            await updateIndexOfTask(transformedData);
            await getTasks();
        }

    };

    const handleTaskView = (id) => {
        navigate(`/project-list/project-details/task-details/${id}?project_id=${projectId}`)
    };


    return (
        <div className='overflow-x-auto h-full'>
            {(taskList?.length === 0 || (columns === null || columns === undefined)) ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd} className='h-full' onDragUpdate={onDragUpdate}>
                    <Container ref={containerRef} className='h-full scroll-container'>
                        <TaskColumnStyles className='h-full'>
                            {Object.entries(columns).map(([columnId, column]) => {
                                return (
                                    <Droppable className='h-full' key={columnId} droppableId={columnId}>
                                        {(provided) => (
                                            <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                                                <div className={`${column?.title === "In progress" ? "text-[#db763b] bg-[#eeb08c3d]" : column?.title === "Completed" ? "text-[#83BF6E] bg-[#83bf6e24]" : column?.title === "To be verified" ? "text-[#8E59FF] bg-[#8e59ff1a]" : column?.title === "Pending" ? "text-[#6F767E] bg-[#6f767e2b]" : "text-[#6F767E] bg-[#6f767e2b]"} px-[10px] py-[2px] rounded self-start`}>
                                                    {column.title}
                                                </div>
                                                {column.items.map((item, index) => {
                                                    return (
                                                        <TaskCard key={item.id} item={item} index={index}
                                                            onDoubleClick={(e) => {
                                                                if (window.getSelection().toString() === '') {
                                                                    handleTaskView(item.id, item?.project?.id);
                                                                }
                                                            }}
                                                            handleTaskComplete={handleTaskComplete}
                                                            getTaskList={getTasks}
                                                        />
                                                    )
                                                })}
                                                {provided.placeholder}
                                                <div className="flex w-full hover:bg-[#dddddd] rounded-lg mt-2.5">
                                                    <button className="grow p-2" onClick={() => handleOpenModal(columnId)}>
                                                        + Add Task
                                                    </button>
                                                </div>
                                            </TaskList>
                                        )}
                                    </Droppable>

                                )
                            })}
                        </TaskColumnStyles>
                    </Container>
                </DragDropContext>
            )}
        </div>
    );
};

export default TaskListKanban;
