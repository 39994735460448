import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import validator from "validator";
import "./ClientListing.css";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import {
  ADD_CLIENT,
  GET_CLIENT,
  UPDATE_CLIENT,
} from "../../../components/utils";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../components/axiosClient";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomPagination from "../../../components/common/CustomPagination";
import secureLocalStorage from "react-secure-storage";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";


const ClientListing = () => {
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [editedId, setEditedId] = useState(null);
  const [listshow, setlistShow] = useState(false);
  const [listallCompany, setListAllCompany] = useState([]);
  const [pageInformation, setPageInformation] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Client")?.access_type !== "Read";

  // for new add client API Call
  const clientSignup = async (payload) => {
    try {
      const res = await postRequest(ADD_CLIENT, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getAllCompany();
          successNotification(res.response.data.message);
          setClientName("");
          setSelectedCountry(null);
          setlistShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  // for Eidt client data  API Call

  const editClientData = async (payload) => {
    try {
      const res = await patchRequest(UPDATE_CLIENT, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getAllCompany();
          successNotification(res.response.data.message);
          setClientName("");
          setSelectedCountry(null);
          setlistShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  // for new add client API Call
  const handleAddCompany = (e) => {
    e.preventDefault();

    if (validator.isEmpty(clientName)) {
      errorNotification("Please Enter Client Name");
    } else if (!selectedCountry) {
      errorNotification("Please Select Country Name");
    } else {
      let companyData = {
        name: clientName,
        country: selectedCountry.value,
      };


      if (editedId) {
        companyData.client_id = editedId;
      }



      {
        editedId ? editClientData(companyData) : clientSignup(companyData);
      }
    }
  };

  useEffect(() => {
    getAllCompany();
  }, [page]);

  // for get all GET_CLIENT list API Call
  const getAllCompany = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_CLIENT}?page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setListAllCompany(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
      } else {
        setListAllCompany([]);
      }
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  //handle add btn click
  const handleAdd = () => {
    setClientName("");
    setSelectedCountry(null);
    setlistShow(true);
  };

  // for edit company listing
  const handleEdit = (item) => {
    setClientName(item.name);
    setSelectedCountry({ label: item.country, value: item.country });
    setEditedId(item.id);
    setlistShow(true);
  };

  const countryArray = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma (Myanmar)",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Gaza Strip",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See (Vatican City)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of the Congo",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Virgin Islands",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna",
    "West Bank",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const countryOptions = countryArray.map((country) => ({
    label: country,
    value: country,
  }));

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="client__section bg-[#fff] rounded-[12px]">
      <div className="client__subsection">
        {role_accesses && (
          <button
            className="add__client mx-3 mt-3"
            onClick={handleAdd}
            disabled={!role_accesses}
          >
            + Add Client
          </button>
        )}

        {listallCompany?.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (<div className="client__listing">
          <div className="client__data pb-2">
            <div className="d-flex align-items-start justify-content-between client__header min-h-[600px]">
              <table className="table client__list__table">
                <thead className="report__header__border">
                  <tr className="border-style">
                    <th
                      scope="col"
                      className="font__weight header__first__child "
                    >
                      Client id
                    </th>
                    <th
                      scope="col"
                      className="font__weight header__first__child"
                    >
                      Client Name
                    </th>
                    <th scope="col" className="font__weight">
                      Country
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {listallCompany.map((item, index) => (
                    <tr key={index}>
                      <td className="table_tr_style align-middle">{item.id}</td>
                      <td className="table_tr_style align-middle text-capitalize">
                        {item.name}
                      </td>
                      <td className="align-middle text-capitalize">
                        {item.country}
                      </td>
                      <td className="text-center align-middle d-flex justify-content-end !border-b-0 ">
                        {role_accesses && (
                          <button
                            className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                            onClick={() => handleEdit(item)}
                            disabled={!role_accesses}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {
              <CustomPagination
                page={page}
                pageInformation={pageInformation}
                setPage={setPage}
                totalPages={pageInformation?.last_page}
              />
            }
          </div>
        </div>)}

        <Modal
          className="client-modal "
          title={editedId ? "Edit Client" : "Add Client"}
          onClose={() => {
            setlistShow(false);
            setEditedId(null);
          }}
          show={listshow}
        >
          <div className="modal-body add__client__form">
            <div>
              <h6 className="modal-heading">Client Name</h6>
              <input
                placeholder="Client Name "
                className="client__name text-capitalize "
                type="text"
                value={clientName}
                autoComplete="off"
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
              />
            </div>
            <div className="mt-3">
              <h6 className="modal-heading">Country</h6>
              <Select
                className="basic-single text-capitalize Employ_Country "
                classNamePrefix="select"
                placeholder="Client Country"
                name="selectedClient"
                options={countryOptions}
                value={selectedCountry}
                onChange={(selectedOption) =>
                  setSelectedCountry(selectedOption)
                }
              />
            </div>
            <div className="add__close__section mt-3">
              {role_accesses && (
                <button
                  type="button"
                  className="client__save__btn"
                  onClick={handleAddCompany}
                  disabled={!role_accesses}
                >
                  {editedId ? "Update" : "Add"}
                </button>
              )}
            </div>
          </div>
        </Modal>

      </div>
    </div>
  );
};

export default ClientListing;
