import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { IoIosCheckmark } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from "react-select";
import validator from 'validator';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import { getRequest, patchRequest, postRequest } from '../../../components/axiosClient';
import CustomPagination from '../../../components/common/CustomPagination';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { ADD_MY_TEAM_TASK, GET_MY_TEAM_TASKS, UPDATE_MY_TEAM_TASK } from '../../../components/utils';
import Modal from '../Modal/Modal';

const MyTeamTask = () => {

    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const id = useParams();
    const [taskData, setTaskData] = useState({
        title: "",
        assigned_to: id.id,
        due_date: null,
        priority: null,
        description: null,
    });
    const [taskList, setTaskList] = useState([]);

    const pageSize = 10;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const pageNo = searchParams.get('pageno');
    const [pageInformation, setPageInformation] = useState({});
    const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

    const statusParams = searchParams.get('status');
    const [statusFilter, setStatusFilter] = useState(statusParams ? statusParams : null);

    const priorityParams = searchParams.get('priority');
    const [priorityFilter, setPriorityFilter] = useState(priorityParams ? priorityParams : null);

    useEffect(() => {
        getTaskList();
    }, [page, statusFilter, priorityFilter])

    const handleTaskView = (id, employee_id) => {
        navigate(`/my-team/my-team-task-details/${id}?employee_id=${employee_id}`)
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    }

    const role_access = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "My Team")?.access_type !== "Read";

    const handleAddTask = () => {
        if (validator.isEmpty(taskData.title)) {
            errorNotification("Task title is required");
        } else {
            addTask(taskData);
            setIsOpen(false);
        }
    }

    const addTask = async (payload) => {
        try {
            const res = await postRequest(ADD_MY_TEAM_TASK, payload, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    setTaskData({
                        title: "",
                        assigned_to: id.id,
                        due_date: null,
                        priority: null,
                        description: null
                    })
                    getTaskList();
                    successNotification(res.response.data.message);
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    }

    const getTaskList = async () => {
        if (!(id?.id)) {
            errorNotification('User not found');
            navigate('/404');
        }
        try {
            setLoading(true);
            let apiUrl = `${GET_MY_TEAM_TASKS}?user_id=${id.id}&page=${page}&limit=${pageSize}`;
            if (statusFilter && statusFilter !== "All") {
                apiUrl += `&status=${statusFilter}`;
            }
            if (priorityFilter && priorityFilter !== "All") {
                apiUrl += `&priority=${priorityFilter}`;
            }
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setTaskList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                errorNotification(res.errormessage);
                setTaskList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };
    const priorityOptionsList = ["Low", "Medium", "High"];
    const priorityOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props,
        icon: customIcons[props]
    }));


    const priorityOptionWithNone = [
        {
            label: "All",
            value: "All",
        },
        ...priorityOption
    ];

    const priorityFilterOption = priorityOptionWithNone.map((props) => (props));

    const statusFilterOptionList = ["All", "To do", "In progress", "To be verified", "Completed"];
    const statusFilterOption = statusFilterOptionList.map((props) => ({
        label: props,
        value: props
    }));

    const handleTaskComplete = async (id, is_completed) => {
        if (id == null || is_completed == null || id == undefined || is_completed == undefined) {
            return;
        }
        const payload = {
            task_id: id,
            is_completed: !is_completed
        }
        try {
            const res = await patchRequest(
                `${UPDATE_MY_TEAM_TASK}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                await getTaskList();
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='p-2 h-full flex flex-col box-border'>
            <div className={`flex flex-row items-center p-2 ${role_access ? 'justify-between' : 'justify-end'}`}>

                {
                    role_access &&
                    <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                        onClick={() => handleOpenModal()}
                    >
                        + Add Task
                    </button>
                }
                <div className='px-4 flex justify-end items-center gap-4 '>
                    <Select
                        className="basic-single text-capitalize md:my-3 !min-w-36"
                        classNamePrefix="select"
                        placeholder="Status"
                        name="Status"
                        options={statusFilterOption}
                        value={statusFilterOption.find(option => option.value === statusFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                status: selectedOption.value,
                            };
                            setSearchParams(new URLSearchParams(updatedParams));
                            setStatusFilter(selectedOption.value);
                            setPage(1);
                        }}
                    />
                    <Select
                        className="basic-single md:my-3 !min-w-36"
                        classNamePrefix="select"
                        placeholder="Priority"
                        name="Priority"
                        options={priorityFilterOption}
                        value={priorityFilterOption.find(option => option.value === priorityFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                priority: selectedOption.value,
                            };
                            setSearchParams(new URLSearchParams(updatedParams));
                            setPriorityFilter(selectedOption.value);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
            <div className='py-4 px-2 grow'>
                {taskList?.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (
                    <div className='flex flex-col min-h-[500px] justify-between'>
                        <table className="table max-w-full">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!w-[40%] !border-b-[#d3d3d3] font-medium">
                                        Name
                                    </th>
                                    <th scope="col" className="!w-[12.5%] !border-b-[#d3d3d3] font-medium">
                                        Due Date
                                    </th>
                                    <th scope="col" className="!w-[12.5%] !border-b-[#d3d3d3] font-medium">
                                        Assign to
                                    </th>
                                    <th scope="col" className="!w-[12.5%] !border-b-[#d3d3d3] font-medium">
                                        Status
                                    </th>
                                    <th scope="col" className="!w-[12.5%] !border-b-[#d3d3d3] font-medium">
                                        Priority
                                    </th>
                                    <th scope="col" className="!w-[10%] !border-b-[#d3d3d3] font-medium text-right">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    taskList?.map((item) => (
                                        <tr key={item.id} className={`align-middle border-b-0  ${item?.is_completed ? 'opacity-50' : 'opacity-100'}`}>
                                            <td className="table_tr_style align-middle">
                                                <div className='flex items-center max-w-[35vw] gap-1'>
                                                    <div className={`rounded-full border flex items-center 
                                                                            ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                                        onClick={async (e) => {

                                                            e.stopPropagation();
                                                            await handleTaskComplete(item.id, item?.is_completed);
                                                        }}
                                                    >
                                                        <IoIosCheckmark />
                                                    </div>
                                                    <div className="truncate">
                                                        {item?.title || "--"}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="table_tr_style align-middle">
                                                {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                            </td>
                                            <td className="table_tr_style align-middle text-capitalize">
                                                {item?.assignee?.first_name && item?.assignee?.last_name ? (item?.assignee?.first_name + " " + item?.assignee?.last_name) : "--"}
                                            </td>
                                            <td className="table_tr_style align-middle">
                                                <div className={`${item?.status === "In progress" ? "text-[#db763b] bg-[#eeb08c3d]" : item?.status === "Completed" ? "text-[#83BF6E] bg-[#83bf6e24]" : item?.status === "To be verified" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item?.status === "Pending" ? "text-[#6F767E] bg-[#6f767e2b]" : "text-[#6F767E] bg-[#6f767e2b]"}
                                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[130px] d-flex justify-center`}>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            <td className="table_tr_style align-middle">
                                                {
                                                    item?.priority ?
                                                        <span className={`flex flex-row gap-1 justify-start items-center`}>
                                                            {customIcons[item?.priority]} {item?.priority}
                                                        </span>
                                                        : '--'
                                                }
                                            </td>
                                            <td className="border-b-0 !border-b-[#EFEFEF] text-right">
                                                <div className='flex gap-2 justify-end items-center'>
                                                    {/* {(role_access) && <img
                                                        src={trash}
                                                        className="text-gray-700 cursor-pointer hover:text-red-500 w-[22px] h-[22px]"
                                                        alt="trash-icon" onClick={() => handleDeleteTask(item.id)} />} */}
                                                    <button className='border-0 text-white rounded-md py-2 px-2.5 font-medium bg-[#f47e53]'
                                                        onClick={() => handleTaskView(item.id, id.id)}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <CustomPagination
                            page={page}
                            pageInformation={pageInformation}
                            setPage={setPage}
                            totalPages={pageInformation?.last_page}
                        />
                    </div>
                )}

            </div>
            <Modal
                className="min-w-[60vw] task-modal"
                title="Add task"
                onClose={() => setIsOpen(false)}
                show={isOpen}
            >
                <div className='modal-body flex flex-col gap-4' >
                    <Row className=''>
                        <Col xs={12}>
                            <h6 className="mb-2">Task</h6>
                            <input
                                placeholder="Add Task"
                                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                type="text"
                                name="title"
                                value={taskData.title || ""}
                                autoComplete="off"
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <h6 className="mb-2">Priority</h6>
                            <Select
                                className="rounded-lg text-capitalize"
                                options={priorityOption}
                                formatOptionLabel={customFormatOptionLabel}
                                value={taskData.priority ?
                                    { label: taskData.priority, value: taskData.priority, icon: customIcons[taskData.priority] } : null}
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        priority: e.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <h6 className="mb-2">Due Date</h6>
                            <input
                                placeholder="Due Date"
                                className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                type="date"
                                name="due_date"
                                value={taskData.due_date || ""}
                                autoComplete="off"
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        due_date: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h6 className="mb-2">Description</h6>
                            <textarea
                                placeholder="Add description"
                                className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-40"
                                type="text"
                                name="task"
                                value={taskData.description || ""}
                                onChange={(e) => {
                                    setTaskData({
                                        ...taskData,
                                        description: e.target.value,
                                    });
                                }}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                                onClick={handleAddTask}
                            >Save</button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <style>
                {`
                    .task-modal .modal-content{
                        overflow-y : unset;
                    }

                    .css-13cymwt-control{
                        border-color: var(--select-border);    
                    }
                `}
            </style>
        </div>
    )
}

export default MyTeamTask