import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { getRequest } from '../../../components/axiosClient';
import { GET_TRAINEE } from '../../../components/utils';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";

const Training = () => {


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 30;
    const [page, setPage] = useState(2);
    const [traineeList, setTraineeList] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const isMounted = useRef(false);
    const traineeListRef = useRef();



    useEffect(() => {
        if (!isMounted.current) {
            getTraineeList();
            isMounted.current = true;
        }
    }, []);

    const getTraineeList = async () => {
        try {
            // setFirstLoading(true);
            let apiUrl = `${GET_TRAINEE}?page=${1}&limit=${pageSize}`;

            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setTraineeList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
                setPage(2);
            } else {
                setHasMoreData(false);
                errorNotification(res.errormessage);
                setTraineeList([]);
            }
            setFirstLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    }


    const fetchData = useCallback(async () => {
        if (loading || pageInformation?.current_page >= pageInformation?.last_page) return;

        setLoading(true);
        try {
            let apiUrl = `${GET_TRAINEE}?page=${page}&limit=${pageSize}`;

            const res = await getRequest(apiUrl, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setTraineeList((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }
            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false); // No more data available
            }

        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false); // Stop loading
        }
    }, [page, loading, hasMoreData]);

    const handleScroll = () => {
        if (traineeListRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = traineeListRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 200) {
                if (!loading) {
                    fetchData();
                }
            }
        }
    };

    useEffect(() => {
        const divElement = traineeListRef.current;
        let debounceTimeout;

        const handleDebouncedScroll = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(handleScroll, 200);
        };

        if (divElement) {
            divElement.addEventListener("scroll", handleDebouncedScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleDebouncedScroll);
            }
            clearTimeout(debounceTimeout);
        };
    }, [fetchData, loading]);




    return firstLoading ? (
        <LoadingSpinner />
    ) : (
        <div className='p-3 h-full flex flex-col bg-[#fff] rounded-[12px]'>
            <div className='flex flex-row justify-between items-center'>
                {/* button or filter */}
            </div>
            {traineeList?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (<div ref={traineeListRef} className='overflow-x-auto overflow-y-auto grow' >
                <table className="table max-w-full">
                    <thead className="">
                        <tr className="align-middle">
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium ">
                                ID
                            </th>
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                Full Name
                            </th>
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                Reporting person
                            </th>
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                Technology
                            </th>
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                Branch
                            </th>
                            <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                            </th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {
                            traineeList?.map((trainee, index) => (
                                <tr key={index} className="align-middle">

                                    <td className="!border-b-[#EFEFEF]">
                                        {trainee?.employee_id}
                                    </td>
                                    <td className="!border-b-[#EFEFEF]">
                                        {trainee?.first_name + " " + trainee?.last_name}
                                    </td>
                                    <td className="!border-b-[#EFEFEF]">
                                        {trainee?.reporting_person?.first_name + " " + trainee?.reporting_person?.last_name}
                                    </td>
                                    <td className="!border-b-[#EFEFEF]">
                                        {trainee?.technology?.name}
                                    </td>
                                    <td className="!border-b-[#EFEFEF]">
                                        {trainee?.branch?.branch_name}
                                    </td>
                                    <td className="!border-b-[#EFEFEF] text-right">
                                        <button
                                            type="button"
                                            className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                                            onClick={() => {
                                                navigate(`/training/training-list/${trainee?.id}`);
                                            }}>View</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {
                    loading &&
                    <div className='w-full my-2 flex items-center justify-center'>
                        Loading...
                    </div>
                }
            </div>)}
        </div>
    )
}

export default Training