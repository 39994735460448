import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../views/admin/Modal/Modal";
import noDataFound from "../../components/assets/No data-rafiki 1.svg";
import { getRequest } from "../axiosClient";
import CustomPagination from "../common/CustomPagination";
import { errorNotification } from "../toast-notification/common-toast";
import clock_inActive from '../../components/assets/clock_inActive.svg'
import { GET_EMPLOYEE_DROPDOWN_LIST, GET_EMPLOYEE_HOURS, GET_EMPLOYEE_TASK } from "../utils";
import Select from "react-select";
import dayjs from "dayjs";

export default function EmployeeWiseReport({ data }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [listshow, setlistShow] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeDataList, setEmployeeDataList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeDataTaskList, setEmployeeDataTaskList] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [pageInformation, setPageInformation] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const pageSize = 10;

  const getEmployeeOptions = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_EMPLOYEE_DROPDOWN_LIST}`,
        null,
        navigate
      );
      const list = res.data.data.map((item) => {
        let name = item.first_name + " " + item.last_name;
        return { value: item.id, label: name };
      });
      setEmployeeList([...list]);
      setSelectedEmployee(list[0]);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };
  const getEmployeeReportList = async () => {
    try {
      if (!selectedEmployee?.value) {
        return
      }
      setLoading(true);
      const res = await getRequest(
        `${GET_EMPLOYEE_HOURS}?user_id=${selectedEmployee.value}&page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      setEmployeeDataList(res.data.data.page_data);
      setPageInformation(res.data.data.page_information);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  const getEmployeeReportData = async (data) => {
    if (!selectedEmployee?.value) {
      return
    }
    try {
      const res = await getRequest(
        `${GET_EMPLOYEE_TASK}?date=${data.date}&user_id=${selectedEmployee.value}`,
        null,
        navigate
      );
      const tasklist = res.data.data.map((item) => {
        let taskName = item?.task?.title;
        let projectName = item?.task?.project?.name;
        let totalDuration = item?.duration_second;
        return {
          task: taskName,
          project: projectName,
          duration: totalDuration,
          total: data?.duration_second,
        };
      });
      setEmployeeDataTaskList(tasklist);
    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    getEmployeeReportList();
  }, [selectedEmployee, page])

  useEffect(() => {
    getEmployeeOptions();
  }, []);
  // console.log("employeeList", selectedEmployeeData);

  const handleView = (item) => {
    setSelectedEmployeeData(item.label, item.value);
    setlistShow(true);
  };
  return (
    <div className="project__section bg-[#fff] rounded-[12px] p-[10px]">
      <div className="w-full project__subsection">
        <div className="w-full flex flex-row justify-end items-center">
          <Select
            className="basic-single !min-w-[175px]  text-capitalize"
            classNamePrefix="select"
            placeholder="Employee list"
            name="status"
            options={employeeList}
            value={selectedEmployee}
            onChange={(selectedOption) => {
              setPage(1);
              setSearchParams({ "pageno": 1 });
              setSelectedEmployee(selectedOption);
            }}
          />
        </div>
      </div>
      {employeeDataList?.length === 0 ? (
        <div className="no__transcation">
          <img src={noDataFound} alt={noDataFound} loading="lazy" />
        </div>
      ) : (
        <div className="project__listing mt-0 px-0">
          <div className="project__data pb-4">
            <div className="d-flex flex-column flex-md-row align-items-start justify-content-between min-h-[600px] project__data_table ">
              <table className="table project__list__table table-responsive">
                <thead className="report__header__border">
                  <tr className="border-style">
                    <th scope="col">
                      Date
                    </th>
                    <th scope="col">
                      Duration
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {employeeDataList.map((emp) => (
                    <tr key={emp?.value}>
                      <td className="table_tr_style align-middle font-normal">
                        {emp?.date}
                      </td>
                      <td className="table_tr_style align-middle font-normal">
                        {dayjs.duration(emp?.duration_second, 'seconds').format('HH:mm')}
                      </td>
                      <td className="text-center align-middle !border-b-0 d-flex justify-content-end">
                        <button
                          className="bg-[#f47e53] text-[#fff] w-[fit-content] font-normal py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                          onClick={async () => {
                            await getEmployeeReportData(emp);
                            handleView(emp)
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <CustomPagination
              page={page}
              pageInformation={pageInformation}
              setPage={setPage}
              totalPages={pageInformation?.last_page}
            />
          </div>
        </div>
      )}

      <Modal
        id="project modal"
        className="project-modal modal-heading"
        key="project"
        title={"Employee Work data"}
        onClose={() => {
          setlistShow(false);
        }}
        show={listshow}
      >
        <div className="modal-body add__project__form text-center">
          {employeeDataTaskList?.length > 0
            ? (
              <div>
                <table className="table project__list__table table-responsive">
                  <thead className="report__header__border">
                    <tr className="border-style">
                      <th scope="col">
                        Task
                      </th>
                      <th scope="col">
                        Project
                      </th>
                      <th scope="col" >
                        <div className="flex flex-row justify-start items-center gap-2">
                          <img src={clock_inActive} /> {dayjs.duration(employeeDataTaskList?.[0]?.total, "seconds").format("HH:mm")}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>


                    {employeeDataTaskList?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="table_tr_style align-middle font-normal"> {item.task}  </td>
                          <td className="table_tr_style align-middle font-normal"> {item.project || "--"}  </td>
                          <td className="table_tr_style align-middle font-normal">
                            <div className="flex flex-row justify-start items-center gap-2">
                              <img src={clock_inActive} /> {dayjs.duration(item.duration, "seconds").format("HH:mm")}
                            </div> </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                No data found
              </div>
            )}
        </div>
        <Row className="add__close__section mt-3">
          <Col xs={12}>
            <button
              type="button"
              className="project__save__btn "
              onClick={() => setlistShow(false)}
            >
              Close
            </button>
          </Col>
        </Row>
      </Modal>
      <style>
        {`
          .modal-body{
            overflow-y: auto;
          }
        `}
      </style>
    </div>
  );
}
